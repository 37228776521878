import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import SearchDropdown from "../../../Component/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { AddTermDTO } from "../../../Services/Term/TermDTO";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../Store/Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import {
  FORMAT_DD_MM_YYYY,
  calculateDOBFromAge,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TTermAddForm } from "../../../Type/Term/TTermAddForm";
import "./../../../SCSS/ResponsivePages.scss";
// import TermSlice from "../../../Store/Reducer/Term/TermSlice";

function TermProductPage() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const { ADD_FORM } = useAppSelector((state) => state.Term);
  const { Term } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);

  const [formData, setFormData] = useState<TTermAddForm>(ADD_FORM);
  const dispatch = useAppDispatch();

  const annual_income_data: { label: string; value: string }[] = [];
  console.log("formDa", formData);
  // Generate labels and values for lakhs from 2 lakh to 1 crore
  for (let i = 2; i <= 100; i++) {
    const value = `${i * 100000}`;
    let label = "";
    if (i < 100) {
      label = `${i} lakh`;
    } else {
      const croreValue = i / 100;
      label = `${croreValue} crore`;
    }
    annual_income_data.push({ label, value });
  }
  const age_data: { label: string; value: string }[] = [];
  for (let i = 18; i <= 65; i++) {
    age_data.push({ label: `${i} years`, value: `${i}` });
  }
  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "pincode"
            ? !validatePincode(value)
            : attrName === "mobile"
            ? !validateMobileNumber(value)
            : isEmpty(value),
      },
    }));
  };

  const validate_form = () => {
    let hasError = false;
    let data: TTermAddForm = { ...formData };
    data = {
      ...data,
      annualIncome: {
        ...data.annualIncome,
        warning: isEmpty(data.annualIncome.value),
      },
      smoke: {
        ...data.smoke,
        warning: isEmpty(data.smoke.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
      age: {
        ...data.age,
        warning: isEmpty(data.age.value),
      },
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
    };
    if (
      data.annualIncome.warning ||
      data.smoke.warning ||
      data.gender.warning ||
      data.age.warning ||
      data.pincode.warning ||
      data.mobile.warning
    ) {
      hasError = true;
    }
    if (!hasError) {
      add_form(data);
    }
    setFormData({ ...data });
  };

  const add_form = (data: TTermAddForm) => {
    const onSuccess = (res: any) => {
      //  debugger;
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          ADD_FORM: { ...data },
          ADD_FORM_RESPONSE: { ...res?.results?.response },
          PROPOSER_DATA: {
            ...Term.PROPOSER_DATA,
            mobile: { value: data.mobile.value, warning: false },
            pincode: data.pincode,
          },
        })
      );

      setLoader(false);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setLoader(false);
    };
    const ageInDays = parseInt(data.age.value, 10);
    const dob = !isNaN(ageInDays)
      ? FORMAT_DD_MM_YYYY(calculateDOBFromAge(ageInDays))
      : "";

    let params: AddTermDTO = {
      lifeInsurance_module: "TermPlan",
      lifeInsurance_type: "Term Plan",
      termSmoker: data.smoke.value === "Yes" ? "Y" : "N",
      termAnnualIncome: `${data.annualIncome.value}`,
      termAnnualIncomeOther: `${data.annualIncome.value}`,
      termGender: data.gender.value === "Male" ? "M" : "F",
      termDob: `${FORMAT_DD_MM_YYYY(data.age.value)}`,
      termName: data.name.value,
      termMobile: data.mobile.value,
      termPincode: data.pincode.value,
    };
    console.log("data.age.value", data.age.value);

    setLoader(true);
    TERM_SERVICES.addTermFormData(onSuccess, onError, params);
  };
  const gender_data = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const smokinStatus_data = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox termPP">
              <h1 style={{ textAlign: "center" }}>Term Insurance</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            {/* <h2 style={{ textAlign: "center", marginBottom: "12px" }}>
              <span>The Importance of</span> Health Insurance
            </h2> */}
            <p style={{ textAlign: "justify" }}>
              Human life value is an estimate of the financial value of a human
              life. In insurance, this value is used to determine the amount of
              insurance that a person should buy. Simply expressed the sum
              insured must equal the human life value. Term Insurance is the
              simplest and purest form of Life Insurance. Term Insurance
              policies offer protection to an individual for a limited number of
              years and the sum assured is payable only on insured person’s
              death. Term Insurance offers large amount of life cover at a
              relatively low premium rate.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
          alignItems={"center"}>
          <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
            {isMobile ? (
              <h2 style={{ marginBottom: "24px" }}>
                <span>Secure your family's future with our </span>
                Term insurance!
              </h2>
            ) : null}
            <img
              src="./images/left_info_term_pp.svg"
              alt="insurance-policy"
              className="left_info_term"
            />
          </Grid>
          <Grid xs={12} md={6}>
            {isMobile ? null : (
              <h2 style={{ marginBottom: "24px" }}>
                <span>Secure your family's future with our </span>
                Term insurance!
              </h2>
            )}
            <Box className="form-box-pp">
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}>
                <Grid xs={12}>
                  <h6
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}>
                    Find the best term Insurance
                  </h6>
                </Grid>
                <Grid xs={6} md={4}>
                  <SearchDropdown
                    class_name="inputField mb-2"
                    title={"Annual Income"}
                    attrName={"annualIncome"}
                    value={`${formData.annualIncome.value}`}
                    value_update={updateMasterState}
                    data={annual_income_data}
                    warn_status={formData.annualIncome.warning}
                    error_message={"Select Annual Income"}
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <SelectDropdown
                    class_name="inputField  mb-2"
                    title={"Do you smoke?"}
                    attrName={"smoke"}
                    value={formData.smoke.value}
                    value_update={updateMasterState}
                    data={smokinStatus_data}
                    warn_status={formData.smoke.warning}
                    error_message={"Select Smoker"}
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <SelectDropdown
                    class_name="inputField  mb-2"
                    title={"Your Gender"}
                    attrName={"gender"}
                    value={formData.gender.value}
                    value_update={updateMasterState}
                    data={gender_data}
                    warn_status={formData.gender.warning}
                    error_message={"Select Gender"}
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <DatePicker
                    class_name="inputField mb-2"
                    title={"Date of Birth"}
                    value={formData.age.value}
                    attrName={"age"}
                    value_update={updateMasterState}
                    warn_status={formData.age.warning}
                    error_message="Select DOB"
                    min_date={65}
                    max_date={18}
                    default_date={
                      formData.age.value === ""
                        ? subYears(new Date(), 18)
                        : subYears(new Date(), 0)
                    }
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <RKTextField
                    class_name="inputField mb-2"
                    title={"Pincode"}
                    value={formData.pincode.value}
                    attrName={"pincode"}
                    value_update={updateMasterState}
                    warn_status={formData.pincode.warning}
                    error_message={
                      isEmpty(formData.pincode.value)
                        ? "Enter Pincode"
                        : "Enter Valid Pincode"
                    }
                    max_length={6}
                    validation_type="NUMBER"
                  />
                </Grid>
                <Grid xs={6} md={4}>
                  <RKTextField
                    class_name="inputField mb-2"
                    title={"Mobile"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={
                      isEmpty(formData.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter Valid Mobile Number"
                    }
                    max_length={10}
                    validation_type="NUMBER"
                  />
                </Grid>

                <Grid xs={12}>
                  <CustomButton
                    className="primaryBtn"
                    text="View Quotes"
                    onClick={() => {
                      validate_form();
                    }}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12} className="popup-Checkbox">
                  <CustomCheckbox
                    checked={true}
                    // label="By clicking on view quotes you agree to all Terms & Conditions."
                    label={
                      <>
                        By clicking on view quotes you agree to all {""}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                          }>
                          Terms & Conditions
                        </span>
                      </>
                    }
                    defaultChecked
                    value={true}
                    disabled={true}
                    attrName={"termCondition"}
                    // value={termCondition}
                    // value_update={handleCheckbox}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <Box className="redlightbox">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}>
                <Grid xs={12} md={6} className="mb-margin2">
                  <h2 style={{ marginBottom: "12px" }}>
                    <span> What is </span>
                    Term Insurance?
                  </h2>
                  <p style={{ textAlign: "justify" }}>
                    A Term Insurance Policy is a type of life insurance that
                    offers policy coverage for a specific period of time , also
                    known as “Term” and the policy offers a specific sum
                    assured. If the policy holder dies during the term of the
                    policy , the eligible sum assured will be paid by the
                    Insurance company as a lump sum to the family of the
                    deceased. Term insurance Policy is an insurance built to
                    protect your family in case of your untimely demise. Term
                    insurance plan offers high life cover at lower premium
                    Rates.
                  </p>
                </Grid>
                <Grid xs={12} md={6} textAlign={"center"}>
                  <img
                    src="./images/term_ins_desc.svg"
                    alt="term-insurance"
                    className="hi-img"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
          alignItems={"center"}>
          <Grid xs={12}>
            <h2
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="mb-margin2">
              <span>Benefits of</span> Term Insurance
            </h2>
          </Grid>
          <Grid xs={12}>
            <ul className="term_ins_benefits">
              <li>
                <img src="./images/benefit_ti_icon1.svg" alt="" />
                <h6>Financial Protection to Family</h6>
                <p>
                  Offers Financial protection to policy holders family in case
                  of policy owners untimely death.
                </p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon2.svg" alt="" />
                <h6>High Life cover at lower premium rates</h6>
                <p>Offers High Sum Assured at an affordable </p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon3.svg" alt="" />
                <h6>Accidental Death Benefits</h6>
                <p>
                  Offers financial cover incase of permanent disability or death
                  due to an accident.
                </p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon4.svg" alt="" />
                <h6>Whole Life Coverage</h6>
                <p>Provides Life coverage through out the policy term.</p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon5.svg" alt="" />
                <h6>Critical Illness Coverage</h6>
                <p>Critical illness can be covered under additional riders.</p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon6.svg" alt="" />
                <h6>Add on rider benefits</h6>
                <p>Provides additional coverage</p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon7.svg" alt="" />
                <h6>Tax benefits</h6>
                <p>
                  Eligible for Tax benefits under Section 80C of the Income Tax
                  Act, 1961.
                </p>
              </li>
              <li>
                <img src="./images/benefit_ti_icon8.svg" alt="" />
                <h6>Return of Premium Option</h6>
                <p>
                  Policy holder receives survival benefits and receives back the
                  premium paid to the insurance company
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <h2
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="mb-margin2">
              <span>Elgibility Criteria for </span> Term Insurance
            </h2>
          </Grid>
          <Grid xs={12}>
            <Box className="redlightbox">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}>
                <Grid
                  xs={12}
                  md={6}
                  textAlign={"center"}
                  className="mb-margin2">
                  <img
                    src="./images/eligibility_criteria_ti.svg"
                    alt=""
                    className="ti_eligibility-img"
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <ul className="redTicklist term_redlist">
                    <li>
                      <h6>Entry Age</h6>
                      <p>Minimum 18 and Maximum 65</p>
                    </li>
                    <li>
                      <h6>Policy Period</h6>
                      <p>
                        Minimum 3-5 years depending on Insurance Company. No
                        limit for Maximum Policy Period but depends on the
                        insurance company
                      </p>
                    </li>
                    <li>
                      <h6>Who can Buy</h6>
                      <p>
                        Young Professionals, Newly Married, House Wife, Working
                        Women, Taxpayers, Parents, Senior Citizens,
                        Self-Employed People, Non-resident Indians (NRIs)
                      </p>
                    </li>
                    <li>
                      <h6>Pay-out Options</h6>
                      <p>One time amount, monthly basis or yearly basis</p>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <Box className="redborder_box">
              <h2 style={{ marginBottom: "12px" }}>
                <span> Types of </span>
                Term Policies
              </h2>
              <ul className="termPolicyType_list">
                <li>
                  <h6>Straight Term Policies</h6>
                  <p>
                    Policies designed for a specific number of years and
                    automatically terminate at the end of the period. Most
                    Insurance companies offer Five years term , Ten years term,
                    Fifteen years term and Twenty years term on a level premium
                    basis. The level premium basis means that the premium rates
                    will remain the same through out the term.
                  </p>
                </li>
                <li>
                  <h6>Long Term Policies</h6>
                  <p style={{ marginBottom: "10px" }}>
                    The Long Term polcies are of three types
                  </p>

                  <p style={{ marginBottom: "4px" }}>
                    <span style={{ fontWeight: 600 }}>
                      Term to Expectancy :
                    </span>{" "}
                    This type of policy is written for term ,ie policy holder’s
                    life expectancy.{" "}
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    For e.g : If a person has taken the policy at the age of 20
                    years and as per mortality table , expected life is 50 years
                    then the policy will expire at the age of 70.
                  </p>

                  <p style={{ marginBottom: "4px" }}>
                    <span style={{ fontWeight: 600 }}>
                      Life Expectancy Term :
                    </span>{" "}
                    This type of term policy is similar to term to expectancy
                    with an option to the policyholder to convert the term
                    policy to whole life plans. The conversion to whole life
                    plan is admissible either for a drastically reduced amount
                    of policy by continuing the premium payment at the
                    expiration of the term period or for the same amount of
                    protection by paying an increased premium.
                  </p>
                  <p style={{ marginBottom: "10px" }}>
                    For e.g : A man aged 20 buys a Rs. 1,00,000 life expectancy
                    term policy for an annual premium of Rs. 2000/- and at the
                    age of 70, he may pay the same premium and convert to whole
                    life insurance with revised Sum Assured as per table or he
                    may start paying a higher premium and continue the full
                    coverage of Rs. 1,00,000/-.
                  </p>
                  <p>
                    <span style={{ fontWeight: 600 }}>Term to age :</span> Under
                    this plan the policy can be taken up to a particular age.
                    Accordingly the policy will be termed as term to age 65 if
                    taken up to the age of 65 and term to age 70 means the
                    policy is up to the age of 70.
                  </p>
                </li>
                <li>
                  <h6>Decreasing Terms</h6>
                  <p>
                    Under this type of policy, the amount of insurance is
                    reduced each year while the premium remains the same
                    throughout the period. It is good if an individual has taken
                    a loan from the Bank or Financial Institutions.
                  </p>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <h2 style={{ marginBottom: "24px" }} className="mb-margin1">
              <span> Limitations of </span>
              Term Insurance
            </h2>

            <ul className="limitations_list">
              <li>
                The policyholder may be uninsurable at the expiration of the
                term period and therefore unable to secure new policy. If it is
                available then the premium rate may be very high.
              </li>
              <li>
                An important use of life insurance is to provide income for old
                age but the term insurance is not having any cash values at
                retirement and cannot be used for this purpose.
              </li>
              <li>
                The disadvantage of term insurance for the average person is its
                lack of cash values, which means that if a policyholder is
                unable to meet his premium payment because of financial
                difficulties his protection will expire. There are no cash
                values out of which he can get a loan.
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TermProductPage;
