import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { COLORS } from "../../../../../SupportingFiles/colors";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import Popup from "../../../../Popup/Popup";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const LoginPopup: React.FC<Props> = ({ open, handleClose }) => {
  const [loginSteps, setLoginSteps] = useState<"1" | "2">("1");

  const [formData, setFormData] = useState<{
    otp: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
  }>({
    otp: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  return (
    <Popup
      open={open}
      handleClose={() => {
        setLoginSteps("1");
        handleClose();
      }}
      variant="small"
      title={loginSteps === "1" ? "Customer Login" : "One Time Password"}
      content={
        <Box padding="24px">
          {loginSteps === "1" ? (
            <Box>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}
              >
                <Grid xs={12}>
                  <img src="../images/enter-mobile.svg" width="260px" alt="" />
                </Grid>
                <Grid xs={12}>
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: 600,
                      marginBottom: "8px",
                    }}
                  >
                    Login to your account
                  </h5>
                  <p>Welcome! Enter your mobile number</p>
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter mobile number"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    error_message={"Enter mobile number"}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Grid xs={12}>
                  <CustomButton
                    className="primaryBtn large mb-2"
                    text="Send OTP"
                    onClick={() => setLoginSteps("2")}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
              </Grid>
            </Box>
          ) : loginSteps === "2" ? (
            <Box>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent={"center"}
              >
                <Grid xs={12} marginBottom={""}>
                  <img src="../images/enter-otp.svg" width="260px" alt="" />
                </Grid>
                <Grid xs={12}>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      marginBottom: "8px",
                    }}
                  >
                    OTP sent successfully on{" "}
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      +91-9876543210
                    </span>
                    <Link
                      sx={{
                        background: "rgba(242, 242, 242, 1)",
                        fontSize: "12px",
                        padding: "2px 10px",
                        borderRadius: "40px",
                        color: "rgba(51, 51, 51, 1)",
                        fontWeight: 500,
                        marginLeft: "12px",
                        display: "inline-block",
                        lineHeight: "18px",
                      }}
                      onClick={() => setLoginSteps("1")}
                    >
                      Edit
                    </Link>
                  </p>
                </Grid>
                <Grid xs={6}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Enter OTP"}
                    value={formData.otp.value}
                    attrName={"otp"}
                    value_update={updateMasterState}
                    warn_status={formData.otp.warning}
                    error_message={"Enter OTP"}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                <Grid xs={12}>
                  <CustomButton
                    className="primaryBtn large mb-2"
                    text="Send OTP"
                    onClick={() => setLoginSteps("2")}
                    fullWidth={false}
                    variant="contained"
                  />
                </Grid>
                <Grid xs={12}>
                  <p>
                    Didn’t receive?{" "}
                    <Link sx={{ color: COLORS.themeyellow, fontWeight: 600 }}>
                      Resend OTP
                    </Link>
                  </p>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Box>
      }
    />
  );
};

export default LoginPopup;
