import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HomeContainer from "../../Container/HomeContainer/HomeContainer";
import Aboutus from "../../Page/ResponsivePages/Aboutus/Aboutus";
import CarProductPage from "../../Page/ResponsivePages/CarProductPage/CarProductPage";
import Career from "../../Page/ResponsivePages/Career/Career";
import Claim from "../../Page/ResponsivePages/Claim/Claim";
import Complaint from "../../Page/ResponsivePages/Complaint/Complaint";
import Contact from "../../Page/ResponsivePages/Contact/Contact";
import Disclaimer from "../../Page/ResponsivePages/Disclaimer/Disclaimer";
import GrievancePolicy from "../../Page/ResponsivePages/GrievancePolicy/GrievancePolicy";
import HealthProductPage from "../../Page/ResponsivePages/HealthProductPage/HealthProductPage";
import PrivacyPolicy from "../../Page/ResponsivePages/PrivacyPolicy/PrivacyPolicy";
import RefundCancellation from "../../Page/ResponsivePages/Refund&Cancellation/Refund&Cancellation";
import TWProductPage from "../../Page/ResponsivePages/TWProductPage/TWProductPage";
import TermProductPage from "../../Page/ResponsivePages/TermProductPage/TermProductPage";
import TermsCondition from "../../Page/ResponsivePages/TermsConditions/TermsCondition";
import { COMMON_ROUTES } from "../Path/CommonRoutes";

function CommonRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Routes>
        <Route path={COMMON_ROUTES.HOME} element={<HomeContainer />} />
        <Route path={COMMON_ROUTES.ABOUT_US} element={<Aboutus />} />
        <Route
          path={COMMON_ROUTES.PRODUCT_HEALTH}
          element={<HealthProductPage />}
        />
        <Route
          path={COMMON_ROUTES.PRODUCT_TERM}
          element={<TermProductPage />}
        />
        <Route path={COMMON_ROUTES.PRODUCT_CAR} element={<CarProductPage />} />
        <Route path={COMMON_ROUTES.PRODUCT_TW} element={<TWProductPage />} />
        {/* <Route path={COMMON_ROUTES.ren} element={<TWProductPage />} /> */}
        <Route path={COMMON_ROUTES.CLAIM} element={<Claim />} />
        <Route path={COMMON_ROUTES.CAREER} element={<Career />} />
        <Route path={COMMON_ROUTES.CONTACT} element={<Contact />} />
        <Route path={COMMON_ROUTES.COMPLAINT} element={<Complaint />} />
        <Route
          path={COMMON_ROUTES.TERM_AND_CONDTION}
          element={<TermsCondition />}
        />
        <Route
          path={COMMON_ROUTES.PRIVACY_POLICY}
          element={<PrivacyPolicy />}
        />
        <Route path={COMMON_ROUTES.DISCLAIMER} element={<Disclaimer />} />
        <Route
          path={COMMON_ROUTES.REFUND_CANCELATION}
          element={<RefundCancellation />}
        />
        <Route
          path={COMMON_ROUTES.GRIEVANCE_POLICY}
          element={<GrievancePolicy />}
        />
      </Routes>
    </div>
  );
}

export default CommonRoutes;
