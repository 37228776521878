import React, { useState } from "react";
import { Box, Link, Tab } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";

function Aboutus() {
  const isMobile = useIsMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox aboutus">
              <h1 style={{ textAlign: "center" }}>About Us</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="about-sec">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} textAlign={"center"}>
            <h2 style={{ textAlign: "center" }}>About . Insurance Brokers </h2>
            <p style={{ textAlign: "center" }}>
              . Insurance Brokers Private Limited (MIBPL), founded in 2002, is
              an IRDAI-licensed insurance broking division of . Finance. We are
              one of the leading insurance brokers undertaking direct insurance
              broking in Life and Non - Life businesses in India.  
            </p>
            <p style={{ textAlign: "center" }}>
              As a 100 % subsidiary company of . Finance Ltd (MFIN) , We adhere
              to our parentage’s strong best practises becoming one of the
              trusted insurance brands in India. We offer a wide range of
              insurance solutions through our empanelment with various public
              and private insurance providers. Ably supported by our strong
              lineage, dynamic operations efficiencies and high quality claim
              assistance services, We continue to drive financial inclusion in
              India through our vast and diversified network of 20 own branches
              and 5750 + MFIN branches 
            </p>
            <img src="./images/aboutsus-img.svg" alt="" />
            <p>
               Our Vast Experience spans 20+ years. Through our strategic
              partnership with 30 insurance companies, we offer a diverse range
              of 150 + customised and personalised insurance solutions that
              include 100 - Life, 35 - Health and 15 - General Insurance
              products, helping serve the diverse insurance needs of a large
              customer base.  
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="vision-mission-sec">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <h2 style={{ textAlign: "center" }}>Mission Vs Vision</h2>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} md={6} textAlign={"center"}>
            <span className="red_tag">Vision</span>
            <Box className="shadowBox mb-margin1">
              <p>
                To be renowned as India’s number one insurance broker in terms
                of quality and customer service.
              </p>
              <img
                src="./images/vision-img.svg"
                className="vision-img"
                alt=""
              />
            </Box>
          </Grid>
          <Grid xs={12} md={6} textAlign={"center"}>
            <span className="red_tag">Mission</span>
            <Box className="shadowBox">
              <p>
                To become a household name in India for Insurance broking,
                through our product and service offerings. To Contribute to the
                development of the country by ensuring the social well being of
                our customers.
              </p>
              <img
                src="./images/mission-img.svg"
                className="mission-img"
                alt=""
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default Aboutus;
