import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import "./../../../SCSS/ResponsivePages.scss";

function GrievancePolicy() {
  const isMobile = useIsMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header ">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox refundCancellation">
              <h1 style={{ textAlign: "center" }}>Grievance Policy</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="textContent-pages">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>Grievance Redressal Policy</h5>
            <p>
              We, . Insurance Brokers our aim is to provide our customers the
              best solution for their need and service.we are determined to
              serve our customers at the time of solicitation and in after sales
              service . We are concerned about the complaints/grievance of the
              customers and/or clients.
            </p>
            <p>
              This policy Draft aims at minimising the instances of customer
              complaints and grievances through proper service delivery and
              review mechanism to ensure prompt redressal of customer complaints
              and grievances. The review mechanism is aimed at helping in
              identification of shortcomings in service delivery if any and
              compliance with the stipulations of IRDA regulations.
            </p>
          </Grid>

          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>Grievance Redressal</h5>
            <p>
              To ensure that company’s grievance redressal mechanism is more
              meaningful and effective, a system/ process has been build up
              towards such end. Such system would ensure that the redressal
              sought is just and fair and is permissible within the given
              framework of rules and regulation. The policy document would be
              made available at all branch offices of the company. The concerned
              employees in the respective regional branch offices shall be made
              aware about the complaints/ grievance handling process
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>Process</h5>
            <p>
              If you have a grievance that you wish to redress, you may contact
              us with the details of your grievance through:
            </p>
            <h6 style={{ marginBottom: "6px" }}>Step 1</h6>
            <p>Email:grievance@.life.com</p>
            <h6 style={{ marginBottom: "6px" }}>Step 2</h6>
            <p>
              The company representative have been trained to handle grievance
              with empath
            </p>
            <p>The process followed for addressing queries shall be:</p>
            <ul className="content-listpoints" style={{ marginBottom: "12px" }}>
              <li>
                All grievances will be given acknowledgment receipt within 3
                working days of the receipt of complaint
              </li>
              <li>All couriers will be answered within 14 working days</li>
              <li>
                All grievance from walk in customer will be acknowledged right
                away Based on type of grievance the company shall exercise all
                efforts to address the same including intimation to the
                respective insurer for quick redressal.
              </li>
            </ul>
            <Box className="gp_shadowBox">
              <h6>Grievance Officer</h6>
              <p>
                . Insurance Brokers Private Limited 3 rd floor, Mithun Tower,
                Above sunrise supper market, K.P Valloan Road, Kadavanthra,
                Cochin - 682020
              </p>
            </Box>
            <p>
              In case of no revert for any further clarification, if any, from
              your side within 2 weeks from the date of such resolution, the
              company shall treat the complaint as closed at its end.
            </p>
            <h6 style={{ marginBottom: "6px" }}>Step 3</h6>
            <p>
              If you are still dissatisfied with the decision/resolution to the
              complaint provided by our Grievance officer, you may approach our
              Principal Officer at the address mentioned below:
            </p>
            <Box className="gp_shadowBox">
              <h6>Principal Officer</h6>
              <p>
                . Insurance Brokers Private Limited 3 rd floor, Mithun Tower,
                Above sunrise supper market, K.P Valloan Road, Kadavanthra,
                Cochin - 682020
                <br /> Mail id - principalofficer@.group.com
              </p>
            </Box>
            <p>
              In case you are not satisfied with the response you may please
              feel to write to Insurance Regulatory and Development
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>
              Registration of Complaints with the IRDAI by Policyholders:
            </h5>
            <ul className="content-listpoints">
              <li>
                Can make use of the Integrated Grievance Management System(IGMS)
                - IRDAI Portal at https://igms.irda.gov.in for registering the
                complaints themselves and to monitor the status of the
                complaints
              </li>
              <li>
                Can send the complaint through Email to complaints@irdai.gov.in.
              </li>
              <li>Can call Toll Free No. 155255 or 1800 4254 732</li>
              <li>
                Apart from the above options, if it is felt necessary by the
                complainant to send the communication in physical form, the same
                may be sent to IRDAI addressed to:
              </li>
            </ul>
          </Grid>
          <Grid xs={12}>
            <Box className="gp_shadowBox">
              <h6>General Manager </h6>
              <p>
                Insurance Regulatory and Development Authority of India(IRDAI)
                Consumer Affairs Department – Grievance Redressal Cell.
                <br /> Sy.No.115/1, Financial District, Nanakramguda,
                Gachibowli, Hyderabad – 500 032
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default GrievancePolicy;
