import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "./../../../SCSS/ResponsivePages.scss";
import MailOutline from "@mui/icons-material/MailOutline";

function PrivacyPolicy() {
  const isMobile = useIsMobile();
  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header tc">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox career">
              <h1 style={{ textAlign: "center" }}>Terms & Conditions</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="textContent-pages">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <p>
              This privacy policy describes the management of personal
              information that . Insurance Brokers Limited or its representative
              collects from or about the employee/customer/vendor and describes
              how it is used. The company collects and uses personal data in a
              reasonable and lawful manner. The Company may amend these rules
              from time to time.
            </p>
            <p>
              Please read this Privacy Policy carefully and note that by
              continuing to use the Services or accessing the Platform(s) you
              agree to this Privacy Policy. By accepting this Privacy Policy,
              you represent that you are eighteen years of age or above and that
              you expressly agree to the collection, storage, processing,
              disclosure and/or transfer, and use of your Personal Information
              according to terms set out hereunder.
            </p>
          </Grid>

          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>
              1. Collection of Personal Information and other information
            </h5>
            <p>
              When you use our Platform(s), we collect and store your Personal
              Information as provided by you from time to time. Our primary goal
              in doing so is to provide you a safe, efficient, smooth and
              customized experience. We may collect Personal Information from
              you in a variety of ways, including, but not limited to, when
              Users visit our Platform(s), register on the Platform(s), avail
              our Services and in connection with other activities, services,
              features or resources we make available on our Platform(s).
            </p>
            <p>
              This may include the collection of Personal Information, including
              but not limited to your (a) contact details like name, email
              address, mailing address, phone number, IP address, (b) copies of
              government issued identity cards like PAN, Aadhaar, drivers’
              license, voter ID card, etc. which you may provide voluntarily as
              proof of address and/or identity for our third party customers, as
              may be required under applicable laws; and (c) and non-personally
              identifiable information such as browser name, operating system,
              name of internet service provider, etc., with the goal of
              improving our Service.
            </p>
            <p>
              Please note that we may further collect certain Personal
              Information on behalf of our third-party customers. Consent may be
              provided for such collection and use of your Personal Information
              will be subject to such third parties’ privacy policies and terms
              and conditions.
            </p>
            <p>
              You undertake that you shall be solely responsible for the
              accuracy and truthfulness of the Personal Information you share
              with us, and that the Company will not be responsible for ensuring
              the authenticity of your Personal Information. You may choose to
              request access for reviewing your Personal Information and request
              any modification or amendment of Personal Information by writing
              to the Company grievance officer (“Grievance Officer”) at the
              contact details mentioned in this Privacy Policy.
            </p>
            <p>
              You further understand and acknowledge that provision of your
              Personal Information is voluntary, and you may choose to provide
              and/or withhold provision of any such information. You may further
              choose to withdraw your consent for use/collection of your
              Personal Information at any point by writing to the Grievance
              Officer. However, in case of non-provision of any Personal
              Information or withdrawal of consent from the Company to collect
              and use your Personal Information, you may not be eligible for
              certain services for which such Personal Information needs to be
              collected.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>2. Cookies</h5>
            <p>
              A “cookie” is a small piece of information stored by a web server
              on a web browser so it can be later read back from that browser.
              Cookies are useful for enabling the browser to remember
              information specific to a given user. We place both permanent and
              temporary cookies in your computer’s hard drive. You may choose to
              set their web browser to refuse cookies, or to alert you when
              cookies are being sent.
            </p>
            <h6 style={{ marginBottom: "6px" }}>Log Files</h6>
            <p>
              Log files track user data like IP addresses, browser type, and
              timestamps for analysis. This data helps understand trends, manage
              the site, and enhance services by combining it with other user
              information. It aids in improving marketing, analytics, and
              overall site functionality.
            </p>
            <h6 style={{ marginBottom: "6px" }}>Communication</h6>
            <p>
              If you are no longer interested in receiving e-mail announcements
              and other marketing information from us, please e-mail your
              request to our Grievance Officer. Please note that it may take
              about 10 days to process your request.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>3. Use of your information</h5>
            <p>
              We may use your Personal Information for the following purposes:
            </p>
            <ul className="content-listpoints" style={{ marginBottom: "12px" }}>
              <li>To personalize User experience;</li>
              <li>
                To improve our Platform(s), products and Services we offer;
              </li>
              <li>
                To facilitate the identity verification and know-your-customer
                procedures by our customers with respect to their end customer
                (for instance, legally mandated checks to be implemented by
                insurers for issuance of policies to their customers under
                specific sectoral laws);
              </li>
              <li>To improve customer service;</li>
              <li>
                To send you information and updates pertaining to our Services;
              </li>
              <li>
                To send periodic communication in the form of a newsletter,
                updates about our products or other promotions. Email
                unsubscribe instructions may be included at the bottom of each
                email or User may contact the relevant support team from where
                they have received the email;
              </li>
              <li>
                To contact you via email or phone on your contact details
                provided to offer you information and services pertaining to the
                product you have opted for;
              </li>
              <li>
                To carry out our obligations under agreements with third parties
                including our business partners, group companies, affiliates,
                etc. and to comply with our legal obligations under applicable
                laws in India (“Applicable Laws”);
              </li>
              <li>
                To investigate and resolve any claims, complaints, disputes or
                user grievances.
              </li>
            </ul>
            <p>
              You agree and acknowledge that collection of your Personal
              Information is necessary for the purposes above and the collection
              of the same is in furtherance of a lawful purpose.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>
              4. Retention of Personal Information
            </h5>
            <p>
              We will retain your Personal Information for as long as necessary
              for the purposed described above and for providing our Services.
              We may also retain and use your Personal Information for such
              period as is necessary to comply with Applicable Laws, fulfill our
              legal obligations, resolve disputes, and enforce our agreements.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>
              5. Protection of your Personal Information
            </h5>
            <p>
              We have adopted reasonable security practices, including physical,
              managerial, operation and technical security measures to protect
              against unauthorized access, alteration, disclosure or destruction
              of your Personal Information and data stored by the Company, as
              required under Applicable Laws (as defined hereunder). We further
              endeavor to keep such security practices and procedures updated as
              and when new technology becomes available, and as may be
              appropriate.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>
              6. Sharing your Personal Information
            </h5>
            <p>
              The Company does not sell your personal information with any third
              party for any purpose whatsoever. Keeping this in mind, we may
              disclose, transfer and/or share your Personal Information with
              certain persons, including persons outside India, for the
              following cases:
            </p>
            <ul className="content-listpoints" style={{ marginBottom: "12px" }}>
              <li>
                To our partners, affiliate entities, employees, consultants,
                contractors, independent service providers and other
                third-parties and persons who are bound by contractual
                obligations to keep User Personal Information confidential and
                use it only for the purposes for which we disclose it to them,
                namely for providing our Services or to assist us with our
                operating the Platform(s).
              </li>
              <li>
                For administering activities such as detection of identity
                theft, fraud and other potentially illegal acts, to facilitate
                joint or co-branded Services that you request where such
                services are provided by more than one corporate entity. We may
                share your information with these third parties for those
                limited purposes provided that you have given us your
                permission.
              </li>
              <li>
                We may disclose personal information if required to do so by law
                or in the good faith belief that such disclosure is reasonably
                necessary to fulfill our obligations under any Applicable Law or
                enforce our legal rights, pursue our legal remedies, respond to
                subpoenas, court orders, or any other legal process.
              </li>
              <li>
                For any other purpose in connection with the purposes above.
              </li>
            </ul>
            <p>
              Other than as prescribed under this Privacy Policy, we will not
              disclose any User Personal Information to any external
              organization unless{" "}
            </p>
            <p>(i) we have obtained the consent of the User; or</p>
            <p>(ii) we are required by law to provide such information.</p>
            <p>
              Notwithstanding anything mentioned hereinabove, the Company shall
              not be responsible for the actions or omissions of the service
              providers or parties with whom the Personal Information is shared,
              nor shall the Company be responsible and/or liable for any
              additional information you may choose to provide directly to any
              service provider or any third party.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>
              7. Links to third party websites
            </h5>
            <p>
              Users may find advertising or other content on our Platform(s)
              that link to the sites and services of our partners and other
              third parties. We do not control the content or links that appear
              on these sites and are not responsible for the practices employed
              by websites linked to or from our Platform(s). Browsing and
              interaction on any other website, including websites which have a
              link to our Platform(s), is subject to that website’s own terms
              and policies. We encourage you to read the privacy policies of any
              third party before proceeding to use their websites, products, or
              services.
            </p>
            <p>
              As noted in Section 1 above, you understand and acknowledge that
              we may further collect certain Personal Information on behalf of
              our third-party customers. The collection and use of your Personal
              Information will be subject to such third parties’ privacy
              policies and terms and conditions.
            </p>
          </Grid>
          <Grid xs={12} marginBottom={"24px"} className="mb-margin1">
            <h5 style={{ marginBottom: "12px" }}>
              8. Updates to this Privacy Policy
            </h5>
            <p>
              We reserve the right to update, modify and change this Privacy
              Policy, from time to time, and we will post those changes on our
              Platform(s) so that you are always aware of how we collect and use
              your Personal Information. We encourage you to frequently check
              our Platform(s) for any change in this Privacy Policy. If you do
              not agree with any revisions to this Privacy Policy, please
              consider refraining from further use of our Services and write to
              our Grievance Officer.
            </p>
          </Grid>
          <Grid xs={12}>
            <h5 style={{ marginBottom: "12px" }}>Complaints & Grievance</h5>
            <p>
              For any complaints, services related issues or policy, claim
              related queries/ customer can reach us on
            </p>
            <ul className="pp_contact_details_box">
              <li>
                <span>
                  <MailOutline />
                </span>
                <Box>
                  {" "}
                  <h6 className="mb-1">Email</h6>
                  <a href="mailto:grievance@.life.com ">grievance@.life.com </a>
                </Box>
              </li>
              <li>
                <span>
                  <PhoneIcon />
                </span>
                <Box>
                  <h6 className="mb-1">Our Toll Free Number</h6>
                  <a href="tel:+91 9388139521">9388139521</a>
                </Box>
              </li>
            </ul>
            <p>For more details : Read our Grievance Policy</p>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default PrivacyPolicy;
