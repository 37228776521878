import React from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MFooter.scss";
import { COLORS } from "../../../../SupportingFiles/colors";

function MFooter() {
  return (
    <Box className="mfooter">
      <Box className="top-sec">
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12}>
            <Box textAlign={"center"} marginBottom={"24px"}>
              <img
                src="./images/1clickpolicy-white-logo.svg"
                height="60px"
                alt="1clickpolicy"
              />
            </Box>
          </Grid>
          <Grid xs={6} marginBottom={"16px"}>
            <h6 className="mb-3">Address</h6>
            <p>
              A-26/1, DLF Shopping Mall, DLF Phase 1, DT mega mall ,Gurugram
              -122001, Haryana
            </p>
          </Grid>
          <Grid xs={6} marginBottom={"16px"}>
            <h6 className="mb-3">Products</h6>
            <ul>
              <li>
                <Link>Health insurance</Link>
              </li>
              <li>
                <Link>Term insurance</Link>
              </li>
              <li>
                <Link>Car insurance</Link>
              </li>
              <li>
                <Link>Two Wheeler insurance</Link>
              </li>
            </ul>
          </Grid>
          <Grid xs={6} marginBottom={"16px"}>
            <h6 className="mb-4">Company</h6>
            <ul>
              <li>
                <Link>About Us</Link>
              </li>
              <li>
                <Link>Terms & Conditions</Link>
              </li>
              <li>
                <Link>Privacy Policy</Link>
              </li>
              <li>
                <Link>Disclaimer</Link>
              </li>
              <li>
                <Link>Refund & Cancellation</Link>
              </li>
              <li>
                <Link>Grievance Policy</Link>
              </li>
              <li>
                <Link>Site Map</Link>
              </li>
            </ul>
          </Grid>
          <Grid xs={6} marginBottom={"16px"}>
            <h6 className="mb-3">Services</h6>
            <ul>
              <li>
                <Link>Claim</Link>
              </li>
              <li>
                <Link>Complaint</Link>
              </li>
              <li>
                <Link>Career</Link>
              </li>
              <li>
                <Link>Contact</Link>
              </li>
            </ul>
          </Grid>

          {/* <Grid xs={12} marginBottom={"16px"}>
            <h6 className="mb-4">Contact Us</h6>
            <ul>
              <li>
                <p>
                  Principal Officer: <span>Suresh K</span>
                </p>
              </li>
              <li>
                <p>
                  Mail:{" "}
                  <a href="mailto:principalofficer@.group.com">
                    principalofficer@.group.com
                  </a>
                </p>
              </li>
              <li>
                <p>
                  IRDAI License No: <span>459 </span>
                </p>
              </li>
              <li>
                <p>
                  License Validity:{" "}
                  <span>
                    <span style={{ display: "inline-block" }}>02-09-2022</span>{" "}
                    -{" "}
                    <span style={{ display: "inline-block" }}>01-09-2025</span>
                  </span>
                </p>
              </li>
              <li>
                <p>
                  CIN No: <span>U67200KL2002PTC015200</span>
                </p>
              </li>
              <li>
                <p>
                  IBAI Membership No: <span>43459</span>
                </p>
              </li>
              <li>
                <Box className="social-icons">
                  <Link>
                    <img src="./images/fb-icon.svg" alt="" />
                  </Link>
                  <Link>
                    <img src="./images/linkedin-icon.svg" alt="" />
                  </Link>
                  <Link>
                    <img src="./images/youtube-icon.svg" alt="" />
                  </Link>
                </Box>
              </li>
            </ul>
          </Grid> */}
        </Grid>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} textAlign={"center"}>
            <p
              style={{
                display: "inline-block",
                color: "rgba(37, 37, 37, 1)",
                padding: "8px 16px",
                borderRadius: "24px",
                background: COLORS.white,
                fontSize: "10px",
                lineHeight: "16px",
              }}
            >
              Insurance is the subject matter of the solicitation. For more
              details on policy terms, conditions, exclusions, limitations,
              please refer/read policy brochure carefully before concluding
              sale.
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box className="copyright-bar">
        <p style={{ textAlign: "center" }}>
          © {new Date().getFullYear()} 1 Click Policy | All Rights Reserved.
        </p>
      </Box>
    </Box>
  );
}

export default MFooter;
