import { NODE_DOMAIN, PHP_DOMAIN } from "../CommonURLs/Domain";

const DOMAIN = process.env.REACT_APP_BACKEND_URL;

export const URL_CONSTANTS = {
  // CAR URL
  MASTER_DATA_CAR_MAKE_AND_MODEL: `${DOMAIN}car-master-datas/get-lists.json`,
  ADD_CAR_DATA: `${DOMAIN}car-insurances/add.json`,
  MASTER_DATA_CAR_VARIANT: `${DOMAIN}car-master-datas/get-variant-lists.json`,
  MASTER_DATA_CAR_FUEL_TYPE: `${DOMAIN}car-master-datas/get-fuels.json`,
  CAR_UPDATE_DETAIL: `${DOMAIN}car-insurances/update-policy-details.json`,
  CAR_SINGLE_QUOTATION: `${NODE_DOMAIN}api/v1/premium/global2`,
  CAR_QUOTE_ADDONS: `${DOMAIN}car-insurances/update-addon.json`,
  CAR_QUOTE_IDV: `${DOMAIN}car-insurances/update-idv.json`,
  UPDATE_CAR_ACCESSORIES: `${NODE_DOMAIN}/api/v1/premium/update-accessories`,
  UPDATE_CAR_DISCOUNT: `${NODE_DOMAIN}/api/v1/premium/update-discount-details`,
  CAR_GET_PRODUCT_LIST: `${DOMAIN}car-insurances/get-product-lists.json`,
  // TERM URL
  ADD_TERM_FORM: `${PHP_DOMAIN}/api/term-plans/add.json`,
  TERM_QUOTES: `${PHP_DOMAIN}/api/term-plans/get-quotes.json`,
  TERM_QUOTES_PRODUCT: `${PHP_DOMAIN}/api/products/get-term-product-lists.json`,
  TERM_UPDATE_DETAILS: `${PHP_DOMAIN}/api/term-plans/update-record.json`,
  BUY_ACTION: `${PHP_DOMAIN}/api/term-plans/add-buy-now.json`,
  TERM_RIDERS_DETAILS: `${PHP_DOMAIN}/api/iprus-term-v1/get-rider-details.json`,
  PERPOSAL_TERM_DETAILS: `${PHP_DOMAIN}/api/term-plans/add-proposal-data.json`,
  PROPOSER_OCCUPATION_DETAILS: `${PHP_DOMAIN}/api/term-plans/get-occupation-list.json`,
  PROPOSER_QUALIFICATION_DETAILS: `${PHP_DOMAIN}/api/term-plans/get-education-list.json`,
  GET_REDIRECTION_URL: `${PHP_DOMAIN}/api/term-plans/get-proposal.json`,
  UPDATE_RIDERS_STATUS: `${PHP_DOMAIN}/api/term-plans/get-riders.json`,
};
