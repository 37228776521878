import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import RadioText from "../../../Component/InputFields/RadioText/RadioText";
import SearchDropdown from "../../../Component/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import { CAR_ROUTES } from "../../../Routing/Path/CarRoutes";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { CAR_ADD_FORM_SERVICES } from "../../../Services/Car/CarAddFormServices";
import { CarSlice } from "../../../Store/Reducer/Car/CarSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  FORMAT_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
  validateMobileNumber,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import { ADD_CAR_DTO } from "../../../Type/Car/Form/AddCarDTO";
import {
  TCarAddForm,
  TCarAddFormResponse,
} from "../../../Type/Car/TCarSlice/TCarSlice";
import { TDropdown } from "../../../Type/Common/TDropdown";
import "./../../../SCSS/ResponsivePages.scss";

function CarProductPage() {
  const [carFormSteps, setCarFormSteps] = useState<
    "oldStep1" | "oldStep2" | "newStep1" | "newStep2"
  >("oldStep1");
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [vehicleInfoStatus, setVehicleInfoStatus] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { Car } = useAppSelector((state) => state);
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Car);

  const { ADD_FORM } = useAppSelector((state) => state.Car);

  const useInputChange = (initialValue: string) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };
    return { value, onChange: handleChange };
  };
  console.log("formData");

  const { value: ncbValue, onChange: changeNCB }: any = useInputChange("0");
  const { value, onChange }: any = useInputChange("Yes");
  console.log(value, "--");

  const [formData, setFormData] = useState<TCarAddForm>({
    business_type: "Rollover",
    reg_no: { value: "", warning: false },
    reg_date: { value: null, warning: false },
    make_model: { value: "", warning: false },
    fuel_type: { value: "", warning: false },
    variant_cc: { value: "", warning: false },
    previous_insurer: { value: "", warning: false },
    policy_expiry_date: { value: "", warning: false },
    mobile: { value: "", warning: false },
    rto: { value: "", warning: false },
    claimed: value,
    anti_theft_device: "No",
    automobile_association: "0",
    deductible: "0",
    make: { value: "", warning: false },
    model: { value: "", warning: false },
    owner_name: { value: "", warning: false },
    prev_ncb: "0",
    vehicle_owned: "Individual",
  });

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev: any) => {
      if (attrName === "make_model") {
        return {
          ...prev,
          make_model: { value: value, warning: isEmpty(value) },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        };
      }

      if (attrName === "fuel_type") {
        return {
          ...prev,
          fuel_type: { value: value, warning: isEmpty(value) },
          variant_cc: { value: "", warning: false },
        };
      }

      return {
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName === "mobile"
              ? !validateMobileNumber(value)
              : attrName === "reg_no"
              ? !validateRegno(value)
              : isEmpty(value),
        },
      };
    });
  };
  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      business_type: "Rollover",
      reg_no: { value: "", warning: false },
      reg_date: { value: null, warning: false },
      make_model: { value: "", warning: false },
      fuel_type: { value: "", warning: false },
      variant_cc: { value: "", warning: false },
      rto: { value: "", warning: false },
      previous_insurer: { value: "", warning: false },
      policy_expiry_date: { value: "", warning: false },
      mobile: { value: "", warning: false },
      claimed: value,
      anti_theft_device: "No",
      automobile_association: "0",
      deductible: "0",
      make: { value: "", warning: false },
      model: { value: "", warning: false },
      owner_name: { value: "", warning: false },
      prev_ncb: "0",
      vehicle_owned: "Individual",
    }));
  }, []);

  useEffect(() => {
    GET_MAKE_MODEL_LIST();
    GET_RTO_LIST();
    GET_PREV_INSURER_LIST();
  }, []);
  useEffect(() => {
    GET_FUEL_TYPE_LIST();
  }, [formData.make_model.value]);
  useEffect(() => {
    GET_VARIANT_LIST();
  }, [formData.fuel_type.value]);
  const GET_MAKE_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      const results = res.data.results;
      const error = results.error;

      let data: TDropdown[] = [];
      if (error === false) {
        const response = results.response;
        data = Object.values(response).map((item: any) => ({
          value: item,
          label: item,
        }));
      }

      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "MAKE_MODEL_LIST",
          value: data,
        })
      );
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    CAR_ADD_FORM_SERVICES.GET_MAKE_MODEL_LIST(onError, onSuccess);
  };
  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      let data: TDropdown[] = [];
      const response = res.results.response;
      const response_array = Object.entries(response);

      response_array.forEach((elment) => {
        data.push({
          value: `${elment[1]}`,
          label: `${elment[1]}`,
        });
      });

      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "FUEL_TYPE_LIST",
          value: data,
        })
      );
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    CAR_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onSuccess,
      onError,
      isEmpty(formData.make_model.value)
        ? `${formData.make_model.value}`
        : `${formData.make_model.value}`
    );
  };
  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      let data = Object.values(res.results.response).map((item: any) => ({
        value: item,
        label: item,
      }));

      dispatch(
        CarSlice.actions.SET_DROPDOWN_DATA({
          key: "VARIANT_LIST",
          value: data,
        })
      );
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    CAR_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onSuccess,
      onError,
      `${formData.make_model.value}`,
      `${formData.fuel_type.value}`
    );
  };
  const GET_RTO_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.data.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;

        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: `${brand} - ${value}`, value: brand })
        );

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "RTO_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  };
  const GET_PREV_INSURER_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.data.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;

        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: `${value}`, value: `${value}` })
        );

        dispatch(
          CarSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "PREVIOUS_INSURER_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    CAR_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };

  const GET_VEHICLE_INFO = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const response = results.response;
      const error = results.error;

      if (error === false && response) {
        const {
          make,
          make_model,
          variant_cc,
          fuel_type,
          policy_expiry_date,
          previous_insurer,
          owner_name,
        } = response;

        setFormData((prevFormData) => ({
          ...prevFormData,
          make: { value: make, warning: false },
          model: { value: make_model, warning: false },
          make_model: { value: make_model, warning: false },
          variant_cc: { value: variant_cc, warning: false },
          fuel_type: { value: fuel_type, warning: false },
          policy_expiry_date: { value: policy_expiry_date, warning: false },
          previous_insurer: { value: previous_insurer, warning: false },
          owner_name: { value: owner_name, warning: false },
        }));

        setVehicleInfoStatus(true);
      }
    };
    const onError = () => {};

    CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
      onSuccess,
      onError,
      formData.reg_no.value
    );
  };

  const POST_CAR_FORM = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      const message = results.message;
      setLoader(false);
      if (!error) {
        const response: TCarAddFormResponse = results.response;

        dispatch(
          CarSlice.actions.BULK_UPDATE({
            ...Car,
            ADD_FORM_RESPONSE: response,
            ADD_FORM: {
              ...Car.ADD_FORM,
              make_model: { value: response.make_model, warning: false },
            },
          })
        );

        const quote_no = response.quote_no;

        window.location.href = `${FRONTEND_DOMAIN}${CAR_ROUTES.QUOTE}?type=CAR&quote_no=${quote_no}`;
        // navigate(CAR_ROUTES.QUOTE);
      } else {
        toast.error(`${message}`);
      }
    };

    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    console.log("formData.claimed", formData.claimed);
    const data: ADD_CAR_DTO = {
      carBusinessType:
        formData.reg_date.value || formData.reg_no.value
          ? "Rollover"
          : formData.rto.value
          ? "New"
          : "Rollover",
      carClaimMade: value,
      carFuelType: formData.fuel_type.value,
      carMakeModel: formData.make_model.value,
      carMobile: formData.mobile.value,
      carPExpiryDate: FORMAT_DD_MM_YYYY(`${formData.policy_expiry_date.value}`),
      carPreviousInsurer: formData.previous_insurer.value,
      carPreviousNCB: ncbValue,
      carRegDate: FORMAT_DD_MM_YYYY(`${formData.reg_date.value}`),
      carRegNo: formData.reg_no.value,
      carRtoCode: formData.rto.value,
      carVariantCC: formData.variant_cc.value,
    };
    setLoader(true);
    CAR_ADD_FORM_SERVICES.GENERATE_QUOTE_NO(onSuccess, onError, data);
  };
  console.log("carFormSteps", carFormSteps);

  const validateForm = () => {
    console.log("formData.business_type:", formData.business_type);
    let hasError = false;
    let data = { ...formData };

    if (carFormSteps === "oldStep1") {
      if (formData.business_type === "Rollover") {
        data = {
          ...data,
          make_model: {
            ...data.make_model,
            warning: isEmpty(data.make_model.value),
          },
          fuel_type: {
            ...data.fuel_type,
            warning: isEmpty(data.fuel_type.value),
          },
          variant_cc: {
            ...data.variant_cc,
            warning: isEmpty(data.variant_cc.value),
          },
          policy_expiry_date: {
            ...data.policy_expiry_date,
            warning: isEmpty(data.policy_expiry_date.value),
          },
          previous_insurer: {
            ...data.previous_insurer,
            warning: isEmpty(data.previous_insurer.value),
          },
          owner_name: {
            ...data.owner_name,
            warning: isEmpty(data.owner_name.value),
          },
          mobile: {
            ...data.mobile,
            warning: !validateMobileNumber(data.mobile.value),
          },
        };

        if (
          data.make_model.warning ||
          data.fuel_type.warning ||
          data.variant_cc.warning ||
          data.previous_insurer.warning ||
          data.policy_expiry_date.warning ||
          data.owner_name.warning ||
          data.mobile.warning
        ) {
          hasError = true;
        }
      }
    } else if (carFormSteps === "newStep2") {
      if (formData.business_type === "New") {
        data = {
          ...data,
          make_model: {
            ...data.make_model,
            warning: isEmpty(data.make_model.value),
          },
          fuel_type: {
            ...data.fuel_type,
            warning: isEmpty(data.fuel_type.value),
          },
          variant_cc: {
            ...data.variant_cc,
            warning: isEmpty(data.variant_cc.value),
          },
          owner_name: {
            ...data.owner_name,
            warning: isEmpty(data.owner_name.value),
          },
          mobile: {
            ...data.mobile,
            warning: !validateMobileNumber(data.mobile.value),
          },
        };
      }
    }
    setFormData({ ...data });

    if (hasError) {
      if (
        data.make_model.warning ||
        data.fuel_type.warning ||
        data.variant_cc.warning ||
        data.owner_name.warning ||
        data.mobile.warning
      )
        return;
    }

    POST_CAR_FORM();
  };

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox carPP">
              <h1 style={{ textAlign: "center" }}>Car Insurance</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <p style={{ textAlign: "justify" }}>
              Government of India has made Third party insurance mandatory under
              the Motor Vehicles Act , 1988. The car insurance offers peace of
              mind for the car owner to drive with out worries. Whether you are
              looking to buy a new car insurance policy , We offer policies to
              cater your needs at incredibly low premiums.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
          alignItems={"center"}>
          <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
            <img
              src="./images/car_with_umbrella.svg"
              alt="insurance-policy"
              className="left_info_term"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <h2 style={{ marginBottom: "24px" }}>
              Car Insurance
              <span> that </span>suit <span>your </span> needs
            </h2>

            <Box className="form-box-pp">
              {(carFormSteps === "oldStep2" || carFormSteps === "newStep2") && (
                <Link
                  sx={{
                    position: "absolute",
                    top: "6px",
                    left: "6px",
                    color: COLORS.black,
                    display: "inline-block",
                  }}
                  onClick={() => {
                    (carFormSteps === "oldStep2" &&
                      setCarFormSteps("oldStep1")) ||
                      (carFormSteps === "newStep2" &&
                        setCarFormSteps("newStep1"));
                    if (true) {
                      setFormData((prev: any) => ({
                        ...prev,
                        business_type: "Rollover",
                        reg_no: {
                          value: formData.reg_no.value,
                          warning: false,
                        },
                        reg_date: {
                          value: formData.reg_date.value,
                          warning: false,
                        },
                        make_model: { value: "", warning: false },
                        fuel_type: { value: "", warning: false },
                        variant_cc: { value: "", warning: false },
                        previous_insurer: { value: "", warning: false },
                        policy_expiry_date: { value: "", warning: false },
                        mobile: { value: "", warning: false },
                        rto: { value: formData.rto.value, warning: false },
                        claimed: value,
                        anti_theft_device: "No",
                        automobile_association: "0",
                        deductible: "0",
                        make: { value: "", warning: false },
                        model: { value: "", warning: false },
                        owner_name: { value: "", warning: false },
                        prev_ncb: "0",
                        vehicle_owned: "Individual",
                      }));
                    }
                  }}>
                  <ChevronLeftRoundedIcon sx={{ fontSize: "30px" }} />
                </Link>
              )}
              {carFormSteps === "oldStep1" &&
                formData.business_type === "Rollover" && (
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      textAlign="center"
                      justifyContent={"center"}>
                      <Grid xs={12}>
                        <h6
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                            marginBottom: "8px",
                          }}>
                          Find the best Car Insurance Plan
                        </h6>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <RKTextField
                          class_name="inputField vehicleNumberField mb-3"
                          title={""}
                          value={formData.reg_no.value}
                          attrName={"reg_no"}
                          value_update={updateMasterState}
                          warn_status={formData.reg_no.warning}
                          error_message={
                            isEmpty(formData.reg_no.value)
                              ? "Enter Reg. Number"
                              : "Enter Valid Reg. Number"
                          }
                          placeholder="MH01AB2345"
                          validation_type="ALL_CAPS"
                          max_length={11}
                        />
                      </Grid>
                      <Grid xs={12} md={6}>
                        <DatePicker
                          class_name="inputField mb-3"
                          title={"Reg. Date"}
                          value={formData.reg_date.value}
                          attrName={"reg_date"}
                          value_update={updateMasterState}
                          warn_status={formData.reg_date.warning}
                          error_message={"Select Reg. Date"}
                          min_date={15 * 12}
                          max_date={3}
                          date_validation_type="MONTHS"
                          default_date={subDays(new Date(), 90)}
                        />
                      </Grid>
                      <Grid xs={12}>
                        <CustomButton
                          className="primaryBtn mb-2"
                          text="Continue"
                          onClick={() => {
                            const data = { ...formData };
                            if (carFormSteps === "oldStep1") {
                              data.reg_no.warning = !validateRegno(
                                data.reg_no.value
                              );
                              data.reg_date.warning = isEmpty(
                                data.reg_date.value
                              );
                              setFormData(data);
                              if (
                                !data.reg_no.warning &&
                                !data.reg_date.warning
                              ) {
                                GET_VEHICLE_INFO();
                                setCarFormSteps("oldStep2");
                              }
                            } else {
                              setCarFormSteps("oldStep2");
                            }
                          }}
                          fullWidth={false}
                          variant="contained"
                        />
                      </Grid>

                      <Grid xs={12} marginBottom={"80px"}>
                        <Link
                          sx={{ color: COLORS.black }}
                          onClick={() => {
                            setCarFormSteps("newStep1");

                            setFormData((prev: any) => ({
                              ...prev,
                              rto: { value: "", warning: false },
                            }));
                          }}>
                          Bought a{" "}
                          <span style={{ color: COLORS.themeyellow }}>
                            New Car
                          </span>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              {carFormSteps === "oldStep2" &&
                formData.business_type === "Rollover" && (
                  <Box>
                    <Grid
                      container
                      columnSpacing={2}
                      textAlign="center"
                      justifyContent={"center"}>
                      <Grid xs={12}>
                        <h6
                          style={{
                            textAlign: "center",
                            fontWeight: 600,
                            marginBottom: "20px",
                          }}>
                          Find the best Car Insurance Plan
                        </h6>
                      </Grid>
                      <Grid xs={12} md>
                        <SearchDropdown
                          class_name="inputField mb-6"
                          title={"Make & Model"}
                          attrName={"make_model"}
                          value={formData.make_model.value}
                          value_update={updateMasterState}
                          data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                          warn_status={formData.make_model.warning}
                          error_message={"Select Make and Model"}
                        />
                      </Grid>
                      <Grid xs={12} md={3}>
                        <SearchDropdown
                          class_name="inputField  mb-6"
                          title={"Fuel Type"}
                          attrName={"fuel_type"}
                          value={formData.fuel_type.value}
                          value_update={updateMasterState}
                          data={DROPDOWN_DATA.FUEL_TYPE_LIST}
                          warn_status={formData.fuel_type.warning}
                          error_message={"Select Fuel Type"}
                        />
                      </Grid>
                      <Grid xs={12} md>
                        <SearchDropdown
                          class_name="inputField mb-6"
                          title={"Variant"}
                          attrName={"variant_cc"}
                          value={formData.variant_cc.value}
                          value_update={updateMasterState}
                          data={DROPDOWN_DATA.VARIANT_LIST}
                          warn_status={formData.variant_cc.warning}
                          error_message={"Select Variant"}
                        />
                      </Grid>
                      <Grid xs={12} paddingTop={0}>
                        <h6
                          style={{
                            textAlign: "center",
                            fontWeight: 500,
                            marginBottom: "10px",
                          }}>
                          Policy Details
                        </h6>
                      </Grid>
                      <Grid xs={12} sx={{ paddingTop: "0px !important" }}>
                        <p
                          style={{ textAlign: "center", marginBottom: "12px" }}>
                          Please enter all the essential details pertaining to
                          the policy.
                        </p>
                      </Grid>
                      <Grid xs={6}>
                        <DatePicker
                          class_name="inputField  mb-6"
                          title={"Policy Expiry"}
                          value={formData.policy_expiry_date.value}
                          attrName={"policy_expiry_date"}
                          value_update={updateMasterState}
                          warn_status={formData.policy_expiry_date.warning}
                          error_message={"Select Policy Expiry Date"}
                          date_validation_type="DAYS"
                          min_date={
                            calculateAgeInDays(`${formData.reg_date.value}`) -
                            90
                          }
                          max_date={
                            calculateAgeInDays(
                              FORMAT_DATE_DD_MM_YYYY(`${new Date()}`)
                            ) - 60
                          }
                        />
                      </Grid>
                      <Grid xs={6}>
                        <SearchDropdown
                          class_name="inputField mb-6"
                          title={"Previous Insurer"}
                          attrName={"previous_insurer"}
                          value={formData.previous_insurer.value}
                          value_update={updateMasterState}
                          data={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                          warn_status={formData.previous_insurer.warning}
                          error_message={"Select Previous Insurer"}
                        />
                      </Grid>
                      <Grid xs={6}>
                        <RKTextField
                          class_name="inputField mb-6"
                          title={"Full Name"}
                          value={formData.owner_name.value}
                          attrName={"owner_name"}
                          value_update={updateMasterState}
                          validation_type="NAME"
                          warn_status={formData.owner_name.warning}
                          error_message={
                            isEmpty(formData.owner_name.value)
                              ? "Enter Full Name"
                              : "Enter Valid Full Name"
                          }
                        />
                      </Grid>
                      <Grid xs={6}>
                        <RKTextField
                          class_name="inputField mb-6"
                          title={"Mobile"}
                          value={formData.mobile.value}
                          attrName={"mobile"}
                          value_update={updateMasterState}
                          warn_status={formData.mobile.warning}
                          max_length={10}
                          validation_type="NUMBER"
                          error_message={
                            isEmpty(formData.mobile.value)
                              ? "Enter Mobile Number"
                              : "Enter Valid Mobile Number"
                          }
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        display="flex"
                        gap="12px"
                        alignItems="center"
                        justifyContent="center"
                        marginBottom={"20px"}>
                        <h6>Claimed in the last year?</h6>
                        <RadioText
                          count="AUTO"
                          orientation="row"
                          variant="small"
                          attrName="claimed"
                          options={[
                            { label: "Yes", value: "Yes" },
                            { label: "No", value: "No" },
                          ]}
                          onChange={(e: any) => onChange(e)}
                          // value={}
                          value={value}
                        />
                      </Grid>
                      {value === "No" ? (
                        <Grid xs={12} marginBottom={"20px"}>
                          <h6>Previous No Claim Bonus (NCB)</h6>
                          <p
                            className="my-2"
                            style={{ fontSize: "12px", color: COLORS.black }}>
                            {" "}
                            (Check it in your last policy){" "}
                          </p>

                          <RadioText
                            count="AUTO"
                            orientation="row"
                            variant="small"
                            attrName="ncb"
                            options={[
                              { label: "0%", value: "0" },
                              { label: "20%", value: "20" },
                              { label: "25%", value: "25" },
                              { label: "35%", value: "35" },
                              { label: "45%", value: "45" },
                              { label: "50%", value: "50" },
                            ]}
                            onChange={(e: any) => changeNCB(e)}
                            value={ncbValue}
                          />
                        </Grid>
                      ) : (
                        value === "Yes"
                      )}

                      <Grid xs={12}>
                        <CustomButton
                          className="primaryBtn mb-2"
                          text="View Quotes"
                          onClick={() => {
                            validateForm();
                            // setCarFormSteps("oldStep2");
                          }}
                          fullWidth={false}
                          variant="contained"
                        />
                        {/* <Link onClick={() => setCarFormSteps("oldStep1")}>
                      Back
                    </Link> */}
                      </Grid>
                      <Grid xs={12} className="popup-Checkbox">
                        <CustomCheckbox
                          // label="By clicking on view quotes you agree to all Terms & Conditions."
                          label={
                            <>
                              By clicking on view quotes you agree to all {""}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                                }>
                                Terms & Conditions
                              </span>
                            </>
                          }
                          attrName={"termCondition"}
                          defaultChecked
                          value={true}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              {carFormSteps === "newStep1" ||
              formData.business_type === "New" ? (
                <Box>
                  <Grid
                    container
                    spacing={2}
                    textAlign="center"
                    justifyContent={"center"}>
                    <Grid xs={12}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          marginBottom: "8px",
                        }}>
                        Find the best Car Insurance Plan
                      </h6>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <SearchDropdown
                        class_name="inputField mb-4"
                        title={"RTO"}
                        attrName={"rto"}
                        value={`${
                          DROPDOWN_DATA.RTO_LIST.find(
                            (option) => option.value === formData.rto.value
                          )?.label ?? ""
                        }`}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.RTO_LIST}
                        warn_status={formData.rto.warning}
                        error_message={"Select RTO"}
                      />
                    </Grid>

                    <Grid xs={12}>
                      <CustomButton
                        className="primaryBtn mb-2"
                        text="Continue"
                        onClick={() => {
                          const data = { ...formData };
                          if (carFormSteps === "newStep1") {
                            data.rto.warning = isEmpty(data.rto.value);
                            setFormData(data);
                            if (!data.rto.warning) {
                              setCarFormSteps("newStep2");
                            }
                          } else {
                            validateForm();
                          }
                        }}
                        fullWidth={false}
                        variant="contained"
                      />
                    </Grid>

                    <Grid xs={12} marginBottom={"80px"}>
                      <Link
                        sx={{ color: COLORS.black }}
                        onClick={() => {
                          setCarFormSteps("oldStep1");
                          setFormData((prev: any) => ({
                            ...prev,
                            reg_date: { value: "", warning: false },
                            reg_no: { value: "", warning: false },
                          }));
                        }}>
                        Old
                        <span style={{ color: COLORS.themeyellow }}> Car</span>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              ) : carFormSteps === "newStep2" ? (
                <Box>
                  <Grid
                    container
                    columnSpacing={2}
                    textAlign="center"
                    justifyContent={"center"}>
                    <Grid xs={12}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          marginBottom: "20px",
                        }}>
                        Find the best Car Insurance Plan
                      </h6>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <SearchDropdown
                        class_name="inputField mb-6"
                        title={"Make & Model"}
                        attrName={"make_model"}
                        value={formData.make_model.value}
                        value_update={updateMasterState}
                        warn_status={formData.make_model.warning}
                        error_message={"Select Make and Model"}
                        data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <SelectDropdown
                        class_name="inputField  mb-6"
                        title={"Fuel Type"}
                        attrName={"fuel_type"}
                        value={formData.fuel_type.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.FUEL_TYPE_LIST}
                        warn_status={formData.fuel_type.warning}
                        error_message={"Select Fuel Type"}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <SearchDropdown
                        class_name="inputField mb-6"
                        title={"Variant"}
                        attrName={"variant_cc"}
                        value={formData.variant_cc.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.VARIANT_LIST}
                        warn_status={formData.variant_cc.warning}
                        error_message={"Select Variant"}
                      />
                    </Grid>

                    <Grid xs={6} md={6}>
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Full Name"}
                        value={formData.owner_name.value}
                        attrName={"owner_name"}
                        value_update={updateMasterState}
                        validation_type="NAME"
                        warn_status={formData.owner_name.warning}
                        error_message={
                          isEmpty(formData.owner_name.value)
                            ? "Enter Full Name"
                            : "Enter Valid Full Name "
                        }
                      />
                    </Grid>
                    <Grid xs={6} md={6}>
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Mobile"}
                        value={formData.mobile.value}
                        attrName={"mobile"}
                        value_update={updateMasterState}
                        warn_status={formData.mobile.warning}
                        error_message={
                          isEmpty(formData.mobile.value)
                            ? "Enter Mobile Number"
                            : "Enter Valid Mobile Number "
                        }
                        validation_type="NUMBER"
                        max_length={10}
                      />
                    </Grid>

                    <Grid xs={12}>
                      <CustomButton
                        className="primaryBtn mb-2"
                        text="View Quotes"
                        onClick={() => {
                          validateForm();
                          // setCarFormSteps("oldStep2");
                        }}
                        fullWidth={false}
                        variant="contained"
                      />

                      {/* <Link onClick={() => setCarFormSteps("newStep1")}>Back</Link> */}
                    </Grid>
                    <Grid xs={12} className="popup-Checkbox">
                      <CustomCheckbox
                        label={
                          <>
                            By clicking on view quotes you agree to all {""}
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                              }>
                              Terms & Conditions
                            </span>
                          </>
                        }
                        attrName={"termCondition"}
                        defaultChecked={true}
                        checked={true}
                        disabled={true}
                        // value={termCondition}
                        // value_update={handleCheckbox}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <Box className="redlightbox">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}>
                <Grid xs={12} md={6} className="mb-margin2">
                  <h2 style={{ marginBottom: "12px" }}>
                    <span> What is </span>
                    Car Insurance?
                  </h2>
                  <p style={{ textAlign: "justify" }}>
                    A car insurance is a contract between car owner and a
                    General Insurance Company wherein the insurance company
                    offers financial support to the car owner against the
                    losses/damages or liabilities arises out of the car usage.
                    Car insurance offers car owner to get insurance cover
                    against financial losses caused by accidents, liabilities
                    and even in case of vehicle theft.
                  </p>
                </Grid>
                <Grid xs={12} md={6} textAlign={"center"}>
                  <img
                    src="./images/car-ins-desc.svg"
                    alt="car-insurance"
                    className="ci-img"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box className="carInsurance-types mb-margin2" marginBottom={"60px"}>
          {isMobile ? (
            <Grid
              container
              columnSpacing={3}
              className="row "
              alignItems={"center"}
              marginBottom={"24px"}>
              <Grid xs={12} md={6} className="mb-margin2">
                <Box className="redlightbox">
                  <Grid container columnSpacing={3} className="row">
                    <Grid xs={12}>
                      <h2
                        style={{ textAlign: "left", marginBottom: "24px" }}
                        className="mb-margin1">
                        <span>Types of </span> Car Insurance <span>Plans</span>
                      </h2>
                      <ul className="redTicklist car_redlist">
                        <li>Comprehensive car insurance</li>
                        <li>Third party insurance</li>
                        <li>Standalone Own Damage</li>
                      </ul>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={12} md={6} textAlign={"center"}>
                <img
                  src="./images/car-crash.svg"
                  alt="car-crash"
                  className="car-crash-img"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              columnSpacing={3}
              className="row "
              alignItems={"center"}
              marginBottom={"24px"}>
              <Grid xs={12} md={6} textAlign={"center"}>
                <img
                  src="./images/car-crash.svg"
                  alt="car-crash"
                  className="car-crash-img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Box className="redlightbox">
                  <Grid container columnSpacing={3} className="row">
                    <Grid xs={12}>
                      <h2
                        style={{ marginBottom: "24px" }}
                        className="mb-margin1">
                        <span>Types of </span> Car Insurance <span>Plans</span>
                      </h2>
                      <ul className="redTicklist car_redlist">
                        <li>Comprehensive car insurance</li>
                        <li>Third party insurance</li>
                        <li>Standalone Own Damage</li>
                      </ul>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}>
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Comprehensive <span>Car Insurance Policy</span>{" "}
              </h4>
              <p style={{ marginBottom: "12px", textAlign: "justify" }}>
                Comprehensive Car Insurance policy provides complete insurance
                cover against third party / person/ property liability and for
                damages to your own vehicle. For the customer , there is an
                option to avail a wide range of rider options such as Zero
                depreciation , roadside assistance , personal accident cover and
                more at the cost of an additional premium. Comprehensive car
                insurance provides financial assistance in the following cases.
              </p>
              <ul className="redTicklist">
                <li>Accidental Damages</li>
                <li>Fire Damages</li>
                <li>Car Theft</li>
                <li>
                  Natural disasters like earth quake , flood, cyclone etc.
                </li>
                <li>Manmade hazards like strikes, riots vandalism etc.</li>
                <li>
                  Third party losses like property damage , bodily injury or
                  death
                </li>
                <li>Personal Accidental Injuries or Death</li>
              </ul>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}>
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Third Party <span>Car Insurance</span>{" "}
              </h4>
              <p style={{ textAlign: "justify" }}>
                Third party Car Insurance is a type of Motor Insurance that
                provide insurance coverage against damages occurred to a third
                party or property. Unlike comprehensive Insurance, It does not
                provide cover for insurance policy holder and the car. The
                premium to be paid for the third party car insurance is
                determined by the Insurance Regulatory and Development Authority
                of India ( IRDAI) and is revised on an annual basis. Government
                of India has made Third party Car Insurance mandatory under the
                Motor Vehicles Act , 1988.
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}>
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Standalone Own Damage <span>Car Insurance</span>{" "}
              </h4>
              <p style={{ marginBottom: "12px", textAlign: "justify" }}>
                As the name suggests , Standalone Own Damage Car Insurance
                Policy offers coverage for own damage of your vehicle. It is a
                standalone insurance policy and is optional to buy for car
                owners in India unlike a third-party insurance policy which is
                mandatory as per the Motor Vehicles Act of 1988. Unforeseen
                events like earthquake , flood , cyclone or many man made
                disasters like terrorism , riots or vandalism can damage your
                car to a great extend, thereby causing huge repair bills. In
                this scenario, Own Damage policy offers coverage for the
                expenses incurred in the repair and cost of replacement of parts
                or procurement of spare parts.
              </p>
              <p style={{ textAlign: "justify" }}>
                Motor Insurance offers coverage to your vehicles from potential
                risks financially. Motor Insurance Policy offers coverage to the
                vehicle owner / driver against financial losses that may incur
                due to accidents or other kinds of damages. As per the Motor
                Vehicles Act 1988 , a valid motor insurance is mandatory in
                India to protect the vehicle owners from potential financial
                risks.
              </p>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <h2
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="mb-margin2">
               Comparison <span>between</span> Third Party <span>and </span>
              Comprehensive Car Insurance
            </h2>
          </Grid>
          <Grid xs={12}>
            <table className="comparsion-table">
              <thead>
                <tr>
                  <th>What is Covered ?</th>
                  <th>Third Party</th>
                  <th>Comprehensive</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Third-Party Vehicle Damage</td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Third-Party Property Damage</td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Own Vehicle Damage</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Vehicle Theft</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Fire Damage</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>No Claim Bonus</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Cashless Claim</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Damage from Natural Calamities</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Personal Accident Covered</td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                  <td>
                    <img src="./images/green-tick.svg" alt="green-tick" />
                  </td>
                </tr>
                <tr>
                  <td>Engine Protection</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <span>Through Add-on</span>
                  </td>
                </tr>
                <tr>
                  <td>Zero Depreciation Cover</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <span>Through Add-on</span>
                  </td>
                </tr>
                <tr>
                  <td>NCB Protection</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <span>Through Add-on</span>
                  </td>
                </tr>
                <tr>
                  <td>Invoice Cover</td>
                  <td>
                    <img src="./images/red-cross.svg" alt="red-cross" />
                  </td>
                  <td>
                    <span>Through Add-on</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12} marginBottom={"40px"} className="mb-margin2">
            <h2 style={{ textAlign: "center" }}>
              Key features <span> of Car Insurance </span>
            </h2>
          </Grid>
          <Grid xs={12}>
            <ul className="keyFeature_ci_list">
              <li>
                <img src="./images/ci_features_icon1.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Own Damage Cover</h6>
                  <p style={{ color: COLORS.black }}>
                    Available under Comprehensive and Standalone plan{" "}
                  </p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon2.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>
                    Third Party Damage Cover
                  </h6>
                  <p style={{ color: COLORS.black }}>
                    Covers third party injury and property damage
                  </p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon3.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Savings</h6>
                  <p style={{ color: COLORS.black }}>
                    Up to 80% on Car Insurance Premium
                  </p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon4.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Car Insurance add-ons</h6>
                  <p style={{ color: COLORS.black }}>
                    10+ add-ons according to the plan
                  </p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon5.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>
                    Personal Accident Cover
                  </h6>
                  <p style={{ color: COLORS.black }}>Upto ₹15 Lakh</p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon6.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Cashless Repairs</h6>
                  <p style={{ color: COLORS.black }}>Available</p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon7.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>Car Insurance Premium</h6>
                  <p style={{ color: COLORS.black }}>Starting @ ₹2072/year*</p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon8.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>
                    Buying/Renewal of Policy
                  </h6>
                  <p style={{ color: COLORS.black }}>Online & Offline</p>
                </Box>
              </li>
              <li>
                <img src="./images/ci_features_icon9.svg" alt="" />
                <Box>
                  <h6 style={{ marginBottom: "8px" }}>No Claim Bonus</h6>
                  <p style={{ color: COLORS.black }}>Upto 50%</p>
                </Box>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12} md={6} className="buying-online-left-sec">
            <h2 style={{ marginBottom: "24px" }} className="mb-margin1">
              <span> Buying insurance </span>
              Policy online !
            </h2>
            <img
              src="./images/man_with_laptop.svg"
              alt="buying-online"
              className="person-laptop-img"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <ul className="orderedlist_policy_points">
              <li>
                <h6>Selection of Type of Plan:</h6>
                <p>
                  One must analyse his/her car insurance needs and requirements
                  and then finalize the type of plan accordingly.
                </p>
              </li>
              <li>
                <h6>Comparing Different Policies:</h6>
                <p>
                  One must first try to research and compare the policies
                  themselves on online platforms, like Turtlemint, to get a
                  clear picture of what suits best according to their budget and
                  requirements.
                </p>
              </li>
              <li>
                <h6>Checking for IDV and Premiums:</h6>
                <p>
                  IDV stands for Insured Declared Value. It refers to the
                  current market value of one’s car and the maximum claim an
                  insurer will pay him/her in case of car damaged repair or
                  stolen.
                </p>
              </li>
              <li>
                <h6>Looking for the Claim Process: </h6>
                <p>
                  One must opt for an insurance company which has a simple and
                  prompt claim process. This can be checked by comparing the
                  Claim Settlement Ratio (CSR) of different companies. Higher
                  the CSR, simpler the claim process.
                </p>
              </li>
              <li>
                <h6>Asking for Add-on Covers: </h6>
                <p>
                  First of all, add-on covers are available only along with
                  Comprehensive Car Insurance. They expand the coverage of one’s
                  policy but also are an added cost to one’s premium. So, one
                  must choose wisely according to his/her needs.
                </p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <h2 style={{ marginBottom: "24px", textAlign: "center" }}>
              <span>Key </span>Considerations <span>Before </span> Purchasing{" "}
              <span>Car Insurance</span>
            </h2>
          </Grid>
          <Grid xs={12}>
            {isMobile ? (
              <Box
                className="key-consideration-box-mobile"
                textAlign={"center"}>
                <ul className="kc_point_list" style={{ marginBottom: "40px" }}>
                  <li>
                    <img src="./images/kc_icon1.svg" alt="" />
                    <p>Insured Declared value</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon2.svg" alt="" />
                    <p>Accident Cover</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon3.svg" alt="" />
                    <p>Cashless facility</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon4.svg" alt="" />
                    <p>Zero Depreciation</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon5.svg" alt="" />
                    <p>Roadside Assistance </p>
                  </li>
                  <li>
                    <img src="./images/kc_icon6.svg" alt="" />
                    <p>Engine Protector</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon7.svg" alt="" />
                    <p>No Claim Bonus</p>
                  </li>
                </ul>
                <img
                  src="./images/kc_car_img.svg"
                  alt=""
                  className="kc_car_img"
                />
              </Box>
            ) : (
              <Box className="key-consideration-box">
                <img
                  src="./images/kc_car_img.svg"
                  alt=""
                  className="kc_car_img"
                />
                <ul className="kc_point_list">
                  <li>
                    <img src="./images/kc_icon1.svg" alt="" />
                    <p>Insured Declared value</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon2.svg" alt="" />
                    <p>Accident Cover</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon3.svg" alt="" />
                    <p>Cashless facility</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon4.svg" alt="" />
                    <p>Zero Depreciation</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon5.svg" alt="" />
                    <p>Roadside Assistance </p>
                  </li>
                  <li>
                    <img src="./images/kc_icon6.svg" alt="" />
                    <p>Engine Protector</p>
                  </li>
                  <li>
                    <img src="./images/kc_icon7.svg" alt="" />
                    <p>No Claim Bonus</p>
                  </li>
                </ul>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12} marginBottom={"40px"} className="mb-margin1">
            <h2>
              Benefits
              <span> of Buying Car Insurance </span>Policy Online
            </h2>
          </Grid>
          <Grid xs={12}>
            <ul className="buying-benefits-ci-list">
              <li>
                <h6 style={{ marginBottom: "12px" }}>Time saving</h6>
                <p style={{ color: COLORS.black }}>
                  Our portal provides concrete and reliable information which
                  will aid you to make right decisions.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Zero Paper work</h6>
                <p style={{ color: COLORS.black }}>
                  As the application is filed digitally in our portal along with
                  the scanned copy of the required documents , the paper work is
                  Zero or minimal
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>
                  Convenient and Time Effective
                </h6>
                <p style={{ color: COLORS.black }}>
                  Our portal assist you to buy policy at your convenient time.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Payment Reminders</h6>
                <p style={{ color: COLORS.black }}>
                  Timely remiders will be sent to you at frequent intervals so
                  you will never miss the policy due date.
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Pay Premium Online</h6>
                <p style={{ color: COLORS.black }}>
                  Wide variety of Digital payment options available
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Easy Endorsement</h6>
                <p style={{ color: COLORS.black }}>
                  Endoresment document can be submitted digitally
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>
                  Soft Copy of the Document
                </h6>
                <p style={{ color: COLORS.black }}>
                  Digital copy of the Policy document can be received via mail
                </p>
              </li>
              <li>
                <h6 style={{ marginBottom: "12px" }}>Beast in class service</h6>
                <p style={{ color: COLORS.black }}>Professional</p>
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12} marginBottom={"40px"} className="mb-margin1">
            <Box className="claim-process-box">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}>
                <Grid xs={12} marginBottom={"12px"}>
                  <h2 style={{ marginBottom: "12px" }}>
                    <span>Claim process</span> of car insurance
                  </h2>
                  <p style={{ color: COLORS.black, textAlign: "justify" }}>
                    The car insurance claim process has also become as easy as
                    buying car insurance. You can also claim your insurance
                    offline by connecting with the insurance company
                    representative and inform them about the claim to be made.
                  </p>
                </Grid>
                <Grid xs={12} marginBottom={"12px"}>
                  <h6>You will have to follow the following steps</h6>
                </Grid>
                <Grid xs={12} sm={8} className="mb-margin1">
                  <ul className="redTicklist">
                    <li>Inform us(. insurance Brokers) about the incident</li>
                    <li>
                      File an FIR in case of theft or loss due to man-made
                      incidents
                    </li>
                    <li>
                      Do not move your vehicle from the spot before the surveyor
                      visits
                    </li>
                    <li>
                      Once the surveyor completes the survey and submits the
                      report, your cashless service option will be available for
                      you to repair your vehicle.
                    </li>
                    <li>
                      Submit the original bills and estimate invoice for
                      reimbursement
                    </li>
                    <li>
                      Submit documents required like policy document or number,
                      RC, driving license, etc.
                    </li>
                    <li>
                      Once the process is completed, the insurance claim will be
                      settled
                    </li>
                  </ul>
                </Grid>
                <Grid xs={12} sm={4} textAlign={"center"}>
                  <img
                    src="./images/claim-process-img.svg"
                    alt="car-insurance"
                    className="claim-process-img"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default CarProductPage;
