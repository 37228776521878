import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import RadioText from "../../../Component/InputFields/RadioText/RadioText";
import SearchDropdown from "../../../Component/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { TW_ROUTES } from "../../../Routing/Path/TWRoutes";
import { ADD_TW_DTO } from "../../../Services/DTO/TWDTO/AddTwDTO";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWServices";
import { TWSlice } from "../../../Store/Reducer/TW/TwSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import {
  FORMAT_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
  validateMobileNumber,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import { TDropdown } from "../../../Type/Common/TDropdown";
import { TTWForm } from "../../../Type/TW/TTwSlice";
import "./../../../SCSS/ResponsivePages.scss";

function TWProductPage() {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [BikeFormSteps, setBikeFormSteps] = useState<
    "oldStep1" | "oldStep2" | "newStep1" | "newStep2"
  >("oldStep1");
  const { Tw } = useAppSelector((state) => state);
  const { ADD_FORM } = useAppSelector((state) => state.Tw);
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Tw);

  const useInputChange = (initialValue: string) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };
    return { value, onChange: handleChange };
  };
  const [vehicleInfoStatus, setVehicleInfoStatus] = useState<boolean>(false);

  const { value: ncbValue, onChange: changeNCB }: any = useInputChange("0");
  const { value, onChange }: any = useInputChange("Yes");
  const [formData, setFormData] = useState<TTWForm>({
    business_type: "Rollover",
    reg_no: { value: "", warning: false },
    reg_date: { value: null, warning: false },
    make_model: { value: "", warning: false },
    fuel_type: { value: "", warning: false },
    variant_cc: { value: "", warning: false },
    previous_insurer: { value: "", warning: false },
    policy_expiry_date: { value: null, warning: false },
    mobile: { value: "", warning: false },
    claimed: value,
    rto: { value: "", warning: false },
    owner_name: { value: "", warning: false },
    prev_ncb: "0",
  });

  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      business_type: "Rollover",
      reg_no: { value: "", warning: false },
      reg_date: { value: null, warning: false },
      make_model: { value: "", warning: false },
      fuel_type: { value: "", warning: false },
      variant_cc: { value: "", warning: false },
      rto: { value: "", warning: false },
      previous_insurer: { value: "", warning: false },
      policy_expiry_date: { value: "", warning: false },
      mobile: { value: "", warning: false },
      claimed: value,
      anti_theft_device: "No",
      automobile_association: "0",
      deductible: "0",
      make: { value: "", warning: false },
      model: { value: "", warning: false },
      owner_name: { value: "", warning: false },
      prev_ncb: "0",
      vehicle_owned: "Individual",
    }));
  }, []);
  useEffect(() => {
    GET_MAKE_MODEL_LIST();
    GET_PREV_INSURER_LIST();
    GET_RTO_LIST();
  }, []);
  useEffect(() => {
    if (
      !isEmpty(formData.make_model.value) &&
      !isEmpty(formData.make_model.value)
    ) {
      GET_FUEL_TYPE_LIST();
    }
  }, [formData.make_model.value]);

  useEffect(() => {
    if (
      !isEmpty(formData.make_model.value) &&
      !isEmpty(formData.make_model.value) &&
      !isEmpty(formData.fuel_type.value)
    ) {
      GET_VARIANT_LIST();
    }
  }, [formData.make_model.value, formData.fuel_type.value]);
  const GET_MAKE_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      const results = res.data.results;
      const error = results.error;

      let data: TDropdown[] = [];
      if (error === false) {
        const response = results.response;
        data = Object.values(response).map((item: any) => ({
          label: item,
          value: item,
        }));
      }

      dispatch(
        TWSlice.actions.SET_DROPDOWN_DATA({
          key: "MAKE_MODEL_LIST",
          value: data,
        })
      );
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    TW_ADD_FORM_SERVICES.GET_MAKE_MODEL_LIST(onError, onSuccess);
  };
  const GET_FUEL_TYPE_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;

        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: brand, value: brand })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "FUEL_TYPE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onSuccess,
      onError,
      `${formData.make_model.value}`
    );
  };
  const GET_VARIANT_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;

        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: brand, value: brand })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "VARIANT_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onSuccess,
      onError,
      `${formData.fuel_type.value}`,
      `${formData.make_model.value}`
    );
  };
  const GET_PREV_INSURER_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.data.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;
        data.push({ label: "Do not remember", value: "Do not remember" });
        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: `${value}`, value: `${value}` })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "PREVIOUS_INSURER_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };
  const GET_RTO_LIST = () => {
    const onSuccess = (res: any) => {
      const response = res.data.results;
      const error = response.error;

      if (error === false) {
        let data: TDropdown[] = [];
        const datas = response.response;

        Object.entries(datas).map(([brand, value]) =>
          data.push({ label: `${brand} - ${value}`, value: brand })
        );

        dispatch(
          TWSlice.actions.UPDATE_DROPDOWN_MASTER({
            key: "RTO_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  };
  const POST_TW_FORM = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
      const error = res.results.error;
      const message = res.results.message;
      if (!error) {
        const response = res?.results?.response;
        const error = res?.results?.error;
        if (!error) {
          window.location.href = `${FRONTEND_DOMAIN}${TW_ROUTES.QUOTE}?type=TW&quote_no=${response.quote_no}`;
        }
      } else {
        toast.error(`${message}`);
      }
    };

    const onError = () => {};

    const data: ADD_TW_DTO = {
      twBusinessType:
        formData.reg_date.value || formData.reg_no.value
          ? "Rollover"
          : formData.rto.value
          ? "New"
          : "Rollover",
      twClaimMade: value,
      twFuelType: formData.fuel_type.value,
      twMakeModel: formData.make_model.value,
      twMobile: formData.mobile.value,
      twPExpiryDate: formData.policy_expiry_date.value,
      twPreviousInsurer: formData.previous_insurer.value,
      twPreviousNCB: ncbValue,
      twRegDate: formData.reg_date.value,
      twRegNo: formData.reg_no.value,
      twRtoCode: formData.rto.value,
      twVariantCC: formData.variant_cc.value,
    };

    TW_ADD_FORM_SERVICES.ADD_FORM_DATA(onSuccess, onError, data);
  };
  const GET_VEHICLE_INFO = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const response = results.response;
      const error = results.error;

      if (error === false && response) {
        const {
          make,
          make_model,
          variant_cc,
          fuel_type,
          policy_expiry_date,
          previous_insurer,
          owner_name,
        } = response;

        setFormData((prevFormData) => ({
          ...prevFormData,
          make: { value: make, warning: false },
          model: { value: make_model, warning: false },
          make_model: { value: make_model, warning: false },
          variant_cc: { value: variant_cc, warning: false },
          fuel_type: { value: fuel_type, warning: false },
          policy_expiry_date: { value: policy_expiry_date, warning: false },
          previous_insurer: { value: previous_insurer, warning: false },
          owner_name: { value: owner_name, warning: false },
        }));

        setVehicleInfoStatus(true);
      }
    };
    const onError = () => {};

    TW_ADD_FORM_SERVICES.GET_VEHICLE_INFO(
      onSuccess,
      onError,
      formData.reg_no.value
    );
  };
  const validateForm = () => {
    let hasError = false;
    let data = { ...formData };

    if (BikeFormSteps === "oldStep1") {
      if (formData.business_type === "Rollover") {
        data = {
          ...data,
          make_model: {
            ...data.make_model,
            warning: isEmpty(data.make_model.value),
          },
          fuel_type: {
            ...data.fuel_type,
            warning: isEmpty(data.fuel_type.value),
          },
          variant_cc: {
            ...data.variant_cc,
            warning: isEmpty(data.variant_cc.value),
          },
          policy_expiry_date: {
            ...data.policy_expiry_date,
            warning: isEmpty(data.policy_expiry_date.value),
          },
          previous_insurer: {
            ...data.previous_insurer,
            warning: isEmpty(data.previous_insurer.value),
          },
          owner_name: {
            ...data.owner_name,
            warning: isEmpty(data.owner_name.value),
          },
          mobile: {
            ...data.mobile,
            warning: !validateMobileNumber(data.mobile.value),
          },
        };

        if (
          data.make_model.warning ||
          data.fuel_type.warning ||
          data.variant_cc.warning ||
          data.previous_insurer.warning ||
          data.policy_expiry_date.warning ||
          data.owner_name.warning ||
          data.mobile.warning
        ) {
          hasError = true;
        }
      }
    } else if (BikeFormSteps === "newStep2") {
      if (formData.business_type === "New") {
        data = {
          ...data,
          make_model: {
            ...data.make_model,
            warning: isEmpty(data.make_model.value),
          },
          fuel_type: {
            ...data.fuel_type,
            warning: isEmpty(data.fuel_type.value),
          },
          variant_cc: {
            ...data.variant_cc,
            warning: isEmpty(data.variant_cc.value),
          },
          owner_name: {
            ...data.owner_name,
            warning: isEmpty(data.owner_name.value),
          },
          mobile: {
            ...data.mobile,
            warning: isEmpty(data.mobile.value),
          },
        };
      }
    }
    setFormData({ ...data });

    if (hasError) {
      if (
        data.make_model.warning ||
        data.fuel_type.warning ||
        data.variant_cc.warning ||
        data.owner_name.warning ||
        data.mobile.warning
      )
        return;
    }

    POST_TW_FORM();
  };

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev: any) => {
      if (attrName === "make_model") {
        return {
          ...prev,
          make_model: { value: value, warning: isEmpty(value) },
          fuel_type: { value: "", warning: false },
          variant_cc: { value: "", warning: false },
        };
      }

      return {
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName === "mobile"
              ? !validateMobileNumber(value)
              : attrName === "reg_no"
              ? !validateRegno(value)
              : isEmpty(value),
        },
      };
    });
  };

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox bikePP">
              <h1 style={{ textAlign: "center" }}>Two Wheeler Insurance</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <p style={{ textAlign: "justify" }}>
              Now a days almost all house holds owns at least one two wheeler,
              it help people to commute freely and firstly. As the number of
              vehicle increases, the risk associated with it also increases.
              Government of India has made Third party insurance mandatory under
              the Motor Vehicle Act , 1988. The car insurance offers peace of
              mind for the car owner to drive with out worries. Whether you are
              looking to buy a new car insurance policy, We offer policies to
              cater your needs at incredibly low premiums.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
          alignItems={"center"}>
          <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
            <img
              src="./images/bike_with_umbrella.svg"
              alt="insurance-policy"
              className="left_info_term"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <h2 style={{ marginBottom: "24px" }}>
              Two Wheeler
              <span> Insurance </span>in <span>Seconds </span>
            </h2>
            <Box className="form-box-pp">
              {(BikeFormSteps === "oldStep2" ||
                BikeFormSteps === "newStep2") && (
                <Link
                  sx={{
                    position: "absolute",
                    top: "6px",
                    left: "6px",
                    color: COLORS.black,
                    display: "inline-block",
                  }}
                  onClick={() => {
                    (BikeFormSteps === "oldStep2" &&
                      setBikeFormSteps("oldStep1")) ||
                      (BikeFormSteps === "newStep2" &&
                        setBikeFormSteps("newStep1"));
                    if (true) {
                      setFormData((prev: any) => ({
                        ...prev,
                        business_type: "Rollover",
                        reg_no: {
                          value: formData.reg_no.value,
                          warning: false,
                        },
                        reg_date: {
                          value: formData.reg_date.value,
                          warning: false,
                        },
                        make_model: { value: "", warning: false },
                        fuel_type: { value: "", warning: false },
                        variant_cc: { value: "", warning: false },
                        previous_insurer: { value: "", warning: false },
                        policy_expiry_date: { value: "", warning: false },
                        mobile: { value: "", warning: false },
                        rto: { value: formData.rto.value, warning: false },
                        claimed: value,
                        anti_theft_device: "No",
                        automobile_association: "0",
                        deductible: "0",
                        make: { value: "", warning: false },
                        model: { value: "", warning: false },
                        owner_name: { value: "", warning: false },
                        prev_ncb: "0",
                        vehicle_owned: "Individual",
                      }));
                    }
                  }}>
                  <ChevronLeftRoundedIcon sx={{ fontSize: "30px" }} />
                </Link>
              )}
              {BikeFormSteps == "oldStep1" ? (
                <Box>
                  <Grid
                    container
                    spacing={2}
                    textAlign="center"
                    justifyContent={"center"}>
                    <Grid xs={12}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          marginBottom: "8px",
                        }}>
                        Find the best Car Insurance Plan
                      </h6>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <RKTextField
                        class_name="inputField vehicleNumberField mb-3"
                        title={""}
                        value={formData.reg_no.value}
                        attrName={"reg_no"}
                        value_update={updateMasterState}
                        warn_status={formData.reg_no.warning}
                        error_message={"Enter Reg. Number"}
                        placeholder="MH01AB2345"
                        validation_type="ALL_CAPS"
                        max_length={11}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <DatePicker
                        class_name="inputField mb-3"
                        title={"Reg. Date"}
                        value={formData.reg_date.value}
                        attrName={"reg_date"}
                        value_update={updateMasterState}
                        warn_status={formData.reg_date.warning}
                        error_message={"Select Reg. Date"}
                        min_date={15 * 12}
                        max_date={3}
                        date_validation_type="MONTHS"
                        default_date={subDays(new Date(), 90)}
                      />
                    </Grid>

                    <Grid xs={12}>
                      <CustomButton
                        className="primaryBtn mb-2"
                        text="Continue"
                        onClick={() => {
                          const data = { ...formData };
                          if (BikeFormSteps === "oldStep1") {
                            data.reg_no.warning = !validateRegno(
                              data.reg_no.value
                            );
                            data.reg_date.warning = isEmpty(
                              data.reg_date.value
                            );
                            setFormData(data);
                            if (
                              !data.reg_no.warning &&
                              !data.reg_date.warning
                            ) {
                              GET_VEHICLE_INFO();
                              setBikeFormSteps("oldStep2");
                            }
                          } else {
                            validateForm();
                          }
                        }}
                        fullWidth={false}
                        variant="contained"
                      />
                    </Grid>

                    <Grid xs={12} marginBottom={"80px"}>
                      <Link
                        sx={{ color: COLORS.black, fontWeight: "500" }}
                        onClick={() => {
                          setBikeFormSteps("newStep1");

                          setFormData((prev: any) => ({
                            ...prev,
                            rto: { value: "", warning: false },
                          }));
                        }}>
                        Bought a{" "}
                        <span style={{ color: COLORS.themeyellow }}>
                          New Bike
                        </span>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              ) : BikeFormSteps == "oldStep2" ? (
                <Box>
                  <Grid
                    container
                    columnSpacing={2}
                    textAlign="center"
                    justifyContent={"center"}>
                    <Grid xs={12}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          marginBottom: "20px",
                        }}>
                        Find the best Car Insurance Plan
                      </h6>
                    </Grid>
                    <Grid xs={12} md>
                      <SearchDropdown
                        class_name="inputField mb-6"
                        title={"Make & Model"}
                        attrName={"make_model"}
                        value={formData.make_model.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                        warn_status={formData.make_model.warning}
                        error_message={"Select Make and Model"}
                      />
                    </Grid>
                    <Grid xs={12} md={3}>
                      <SelectDropdown
                        class_name="inputField  mb-6"
                        title={"Fuel Type"}
                        attrName={"fuel_type"}
                        value={formData.fuel_type.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.FUEL_TYPE_LIST}
                        warn_status={formData.fuel_type.warning}
                        error_message={"Select Fuel Type"}
                      />
                    </Grid>
                    <Grid xs={12} md>
                      <SearchDropdown
                        class_name="inputField mb-6"
                        title={"Variant"}
                        attrName={"variant_cc"}
                        value={formData.variant_cc.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.VARIANT_LIST}
                        warn_status={formData.variant_cc.warning}
                        error_message={"Select Variant"}
                      />
                    </Grid>
                    <Grid xs={12} paddingTop={0}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 500,
                          marginBottom: "10px",
                        }}>
                        Policy Details
                      </h6>
                    </Grid>
                    <Grid xs={12} sx={{ paddingTop: "0px !important" }}>
                      <p style={{ textAlign: "center", marginBottom: "12px" }}>
                        Please enter all the essential details pertaining to the
                        policy.
                      </p>
                    </Grid>
                    <Grid xs={6}>
                      <DatePicker
                        class_name="inputField mb-6"
                        title={"Policy Expiry"}
                        value={formData.policy_expiry_date.value}
                        attrName={"policy_expiry_date"}
                        value_update={updateMasterState}
                        warn_status={formData.policy_expiry_date.warning}
                        error_message={"Select Policy Expiry Date"}
                        date_validation_type="DAYS"
                        min_date={
                          calculateAgeInDays(`${formData.reg_date.value}`) - 90
                        }
                        max_date={
                          calculateAgeInDays(
                            FORMAT_DD_MM_YYYY(`${new Date()}`)
                          ) - 60
                        }
                      />
                    </Grid>
                    <Grid xs={6}>
                      <SearchDropdown
                        class_name="inputField  mb-6"
                        title={"Previous Insurer"}
                        attrName={"previous_insurer"}
                        value={formData.previous_insurer.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                        warn_status={formData.previous_insurer.warning}
                        error_message={"Select Previous Insurer"}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Full Name"}
                        value={formData.owner_name.value}
                        attrName={"owner_name"}
                        value_update={updateMasterState}
                        warn_status={formData.owner_name.warning}
                        error_message={
                          isEmpty(formData.owner_name.value)
                            ? "Enter Full Name"
                            : "Enter Valid Full Name"
                        }
                        validation_type="NAME"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Mobile"}
                        value={formData.mobile.value}
                        attrName={"mobile"}
                        value_update={updateMasterState}
                        warn_status={formData.mobile.warning}
                        error_message={
                          isEmpty(formData.mobile.value)
                            ? "Enter Mobile Number"
                            : "Enter Valid Mobile Number"
                        }
                        max_length={10}
                        validation_type="NUMBER"
                      />
                    </Grid>
                    <Grid
                      xs={12}
                      display="flex"
                      gap="12px"
                      alignItems="center"
                      justifyContent="center"
                      marginBottom={"20px"}>
                      <h6>Claimed in the last year?</h6>
                      <RadioText
                        count="AUTO"
                        orientation="row"
                        variant="small"
                        attrName="claimed"
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                        onChange={(e: any) => onChange(e)}
                        value={value}
                      />
                    </Grid>
                    {value === "No" ? (
                      <Grid xs={12} marginBottom={"16px"}>
                        <h6>Previous No Claim Bonus (NCB)</h6>
                        <p
                          className="my-2"
                          style={{ fontSize: "12px", color: COLORS.black }}>
                          {" "}
                          (Check it in your last policy){" "}
                        </p>
                        <RadioText
                          count="AUTO"
                          orientation="row"
                          variant="small"
                          attrName="ncb"
                          options={[
                            { label: "0%", value: "0" },
                            { label: "20%", value: "20" },
                            { label: "25%", value: "25" },
                            { label: "35%", value: "35" },
                            { label: "45%", value: "45" },
                            { label: "50%", value: "50" },
                          ]}
                          onChange={(e: any) => changeNCB(e)}
                          value={ncbValue}
                        />
                      </Grid>
                    ) : (
                      value === "Yes"
                    )}

                    <Grid xs={12}>
                      <CustomButton
                        className="primaryBtn mb-2"
                        text="View Quotes"
                        onClick={() => {
                          validateForm();
                        }}
                        fullWidth={false}
                        variant="contained"
                      />
                      {/* <Link onClick={() => setBikeFormSteps("oldStep1")}>Back</Link> */}
                    </Grid>
                    <Grid xs={12} className="popup-Checkbox">
                      <CustomCheckbox
                        // label="By clicking on view quotes you agree to all Terms & Conditions."
                        label={
                          <>
                            By clicking on view quotes you agree to all
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                              }>
                              {" "}
                              Terms & Conditions.
                            </span>
                          </>
                        }
                        isChecked={true}
                        defaultChecked={true}
                        attrName={"termCondition"}
                        // value={termCondition}
                        // value_update={handleCheckbox}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : BikeFormSteps == "newStep1" ? (
                <Box>
                  <Grid
                    container
                    spacing={2}
                    textAlign="center"
                    justifyContent={"center"}>
                    <Grid xs={12}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          marginBottom: "8px",
                        }}>
                        Find the best Car Insurance Plan
                      </h6>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <SearchDropdown
                        class_name="inputField mb-4"
                        title={"RTO"}
                        attrName={"rto"}
                        value={`${
                          DROPDOWN_DATA.RTO_LIST.find(
                            (option) => option.value === formData.rto.value
                          )?.label ?? ""
                        }`}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.RTO_LIST}
                        warn_status={formData.rto.warning}
                        error_message={"Select RTO "}
                      />
                    </Grid>

                    <Grid xs={12}>
                      <CustomButton
                        className="primaryBtn mb-2"
                        text="Continue"
                        onClick={() => {
                          const data = { ...formData };
                          if (BikeFormSteps === "newStep1") {
                            data.rto.warning = isEmpty(data.rto.value);
                            setFormData(data);
                            if (!data.rto.warning) {
                              setBikeFormSteps("newStep2");
                            }
                          } else {
                            validateForm();
                          }
                        }}
                        fullWidth={false}
                        variant="contained"
                      />
                    </Grid>

                    <Grid xs={12} marginBottom={"80px"}>
                      <Link
                        sx={{ color: COLORS.black }}
                        onClick={() => {
                          setBikeFormSteps("oldStep1");
                          setFormData((prev: any) => ({
                            ...prev,
                            reg_date: { value: "", warning: false },
                            reg_no: { value: "", warning: false },
                          }));
                        }}>
                        Old
                        <span style={{ color: COLORS.themeyellow }}> Bike</span>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              ) : BikeFormSteps == "newStep2" ? (
                <Box>
                  <Grid
                    container
                    columnSpacing={2}
                    textAlign="center"
                    justifyContent={"center"}>
                    <Grid xs={12}>
                      <h6
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          marginBottom: "20px",
                        }}>
                        Find the best Car Insurance Plan
                      </h6>
                    </Grid>
                    <Grid xs={12}>
                      <SearchDropdown
                        class_name="inputField mb-4"
                        title={"Make & Model"}
                        attrName={"make_model"}
                        value={formData.make_model.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.MAKE_MODEL_LIST}
                        warn_status={formData.make_model.warning}
                        error_message={"Select Make and Model"}
                      />
                    </Grid>
                    <Grid xs={12} md={8}>
                      <SelectDropdown
                        class_name="inputField  mb-4"
                        title={"Fuel Type"}
                        attrName={"fuel_type"}
                        value={formData.fuel_type.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.FUEL_TYPE_LIST}
                        warn_status={formData.fuel_type.warning}
                        error_message={"Select Fuel Type"}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <SearchDropdown
                        class_name="inputField mb-4"
                        title={"Variant"}
                        attrName={"variant_cc"}
                        value={formData.variant_cc.value}
                        value_update={updateMasterState}
                        data={DROPDOWN_DATA.VARIANT_LIST}
                        warn_status={formData.variant_cc.warning}
                        error_message={"Select Variant"}
                      />
                    </Grid>

                    <Grid xs={6} md={6}>
                      <RKTextField
                        class_name="inputField mb-3"
                        title={"Full Name"}
                        value={formData.owner_name.value}
                        attrName={"owner_name"}
                        validation_type="NAME"
                        value_update={updateMasterState}
                        warn_status={formData.owner_name.warning}
                        error_message={
                          isEmpty(formData.owner_name.value)
                            ? "Enter Full Name"
                            : "Enter Valid Full Name"
                        }
                      />
                    </Grid>
                    <Grid xs={6} md={6}>
                      <RKTextField
                        class_name="inputField mb-3"
                        title={"Mobile"}
                        value={formData.mobile.value}
                        attrName={"mobile"}
                        value_update={updateMasterState}
                        warn_status={formData.mobile.warning}
                        error_message={
                          isEmpty(formData.mobile.value)
                            ? "Enter Mobile Number"
                            : "Enter Valid Mobile Number "
                        }
                        max_length={10}
                        validation_type="NUMBER"
                      />
                    </Grid>

                    <Grid xs={12}>
                      <CustomButton
                        className="primaryBtn mb-2"
                        text="View Quotes"
                        onClick={() => {
                          validateForm();
                          // setCarFormSteps("oldStep2");
                        }}
                        fullWidth={false}
                        variant="contained"
                      />
                      {/* <Link onClick={() => setBikeFormSteps("oldStep1")}>Back</Link> */}
                    </Grid>
                    <Grid xs={12} className="popup-Checkbox">
                      <CustomCheckbox
                        label={
                          <>
                            By clicking on view quotes you agree to all {""}
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(COMMON_ROUTES.TERM_AND_CONDTION)
                              }>
                              Terms & Conditions
                            </span>
                          </>
                        }
                        attrName={"termCondition"}
                        isChecked={true}
                        defaultChecked={true}

                        // value={termCondition}
                        // value_update={handleCheckbox}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <Box className="redlightbox">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}>
                <Grid xs={12} md={6} className="mb-margin2">
                  <h2 style={{ marginBottom: "12px" }}>
                    <span> What is </span>
                    Two wheeler Insurance?
                  </h2>
                  <p style={{ textAlign: "justify" }}>
                    Two wheeler Insurance is a type of Motor Insurance Policy
                    designed specifically for two wheeler vehicles like scooters
                    and bikes. It offers financial protection for the vehicle
                    owners if the insured bike /scooter faces any third party
                    liability or potential losses or damages to vehicle.
                  </p>
                </Grid>
                <Grid xs={12} md={6} textAlign={"center"}>
                  <img
                    src="./images/bike-ins-desc.svg"
                    alt="bike-insurance"
                    className="bike-img"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box className="carInsurance-types mb-margin2" marginBottom={"60px"}>
          {isMobile ? (
            <Grid
              container
              columnSpacing={3}
              className="row "
              alignItems={"center"}
              marginBottom={"24px"}>
              <Grid xs={12} md={6} className="mb-margin2">
                <Box className="redlightbox">
                  <Grid container columnSpacing={3} className="row">
                    <Grid xs={12}>
                      <h2
                        style={{ textAlign: "left", marginBottom: "24px" }}
                        className="mb-margin1">
                        <span>Types of </span> Two Wheeler Insurance{" "}
                        <span>Plans</span>
                      </h2>
                      <ul className="redTicklist car_redlist">
                        <li>Comprehensive Cover</li>
                        <li>Third Party Liability Cover</li>
                        <li>Standalone Own Damage (OD) Cover</li>
                      </ul>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={12} md={6} textAlign={"center"}>
                <img
                  src="./images/bike-crash.svg"
                  alt="bike-crash"
                  className="car-crash-img"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              columnSpacing={3}
              className="row "
              alignItems={"center"}
              marginBottom={"24px"}>
              <Grid xs={12} md={6} textAlign={"center"}>
                <img
                  src="./images/bike-crash.svg"
                  alt="car-crash"
                  className="car-crash-img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Box className="redlightbox">
                  <Grid container columnSpacing={3} className="row">
                    <Grid xs={12}>
                      <h2
                        style={{ marginBottom: "24px" }}
                        className="mb-margin1">
                        <span>Types of </span> Two Wheeler Insurance{" "}
                        <span>Plans</span>
                      </h2>
                      <ul className="redTicklist car_redlist">
                        <li>Comprehensive Cover</li>
                        <li>Third Party Liability Cover</li>
                        <li>Standalone Own Damage (OD) Cover</li>
                      </ul>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}>
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Comprehensive <span>Cover</span>
              </h4>
              <p style={{ textAlign: "justify" }}>
                Comprehensive type of two wheeler insurance cover is a
                combination of Third party cover plus own damage cover that is
                ,the damages incurred to the vehicle and third party due to an
                accident. Comprehensive Insurance cover accidental damage,
                protection against vehicle theft, Cover for natural and man-made
                disasters, Compensation in case of complete loss of the vehicle
                and Financial assistance in case of loss due to a fire
                explosion.
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}>
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Third Party <span>Liability Cover</span>
              </h4>
              <p style={{ textAlign: "justify" }}>
                As per the Motor Vehicles Act, 1988 Vehicle owners has to buy
                Third Party Liability Cover (TP) . Third Party Liability
                insurance cover only the losses / damages incurred to a third
                party person ora property by the insured vehicle.
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}>
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Standalone Own Damage <span>Own Damage (OD) Cover</span>
              </h4>
              <p style={{ textAlign: "justify" }}>
                A standalone own-damage insurance policy covers only the damages
                incurred to the policy holder’s Scooter / Bike due to any mishap
                happened. The loss incurred due to Fire, natural / man made
                disasters , accident or theft is covered in the policy.
              </p>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <h2
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="mb-margin2">
               Comparison <span>between</span> Third Party (TP){" "}
              <span> vs </span>
              Own -Damage (OD)<span> vs </span>
              Comprehensive <span>Bike Insurance</span>
            </h2>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ overflowX: "auto" }}>
              <table className="comparison-table-bike">
                <thead>
                  <tr>
                    <th>What is Covered ?</th>
                    <th>Comprehensive</th>
                    <th>Standalone Own Damage</th>
                    <th>Third Party Liability</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Third-party liabilities</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                  </tr>
                  <tr>
                    <td>Own damages</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                  </tr>
                  <tr>
                    <td>Personal accident cover</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                  </tr>
                  <tr>
                    <td>Protection against fire</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                  </tr>
                  <tr>
                    <td>Cover against theft</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}>
          <Grid xs={12}>
            <h2 style={{ marginBottom: "24px", textAlign: "center" }}>
              <span>Key </span>Considerations <span>Before </span> Purchasing{" "}
              <span>Two Wheeler Insurance</span>
            </h2>
          </Grid>
          <Grid xs={12}>
            {isMobile ? (
              <Box
                className="key-consideration-box-mobile"
                textAlign={"center"}>
                <ul className="kc_point_list" style={{ marginBottom: "40px" }}>
                  <li>
                    <img src="./images/kc_bike_icon1.svg" alt="" />
                    <p>Insured Declared value</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon2.svg" alt="" />
                    <p>Accident Cover</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon3.svg" alt="" />
                    <p>Cashless facility</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon4.svg" alt="" />
                    <p>Zero Depreciation</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon5.svg" alt="" />
                    <p>Roadside Assistance </p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon6.svg" alt="" />
                    <p>Engine Protector</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon7.svg" alt="" />
                    <p>No Claim Bonus</p>
                  </li>
                </ul>
                <img
                  src="./images/kc_bike_img.svg"
                  alt=""
                  className="kc_bike_img"
                />
              </Box>
            ) : (
              <Box className="key-consideration-box">
                <img
                  src="./images/kc_bike_img.svg"
                  alt=""
                  className="kc_bike_img"
                />
                <ul className="kc_point_list">
                  <li>
                    <img src="./images/kc_bike_icon1.svg" alt="" />
                    <p>Insured Declared value</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon2.svg" alt="" />
                    <p>Accident Cover</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon3.svg" alt="" />
                    <p>Cashless facility</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon4.svg" alt="" />
                    <p>Zero Depreciation</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon5.svg" alt="" />
                    <p>Roadside Assistance </p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon6.svg" alt="" />
                    <p>Engine Protector</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon7.svg" alt="" />
                    <p>No Claim Bonus</p>
                  </li>
                </ul>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row mb-margin2 tw-coverage-section"
          marginBottom={"60px"}>
          <Grid xs={12} marginBottom={"40px"} className="mb-margin2">
            <h2 style={{ textAlign: "center" }}>
              <span>Coverage Under </span>Two Wheeler Insurance
            </h2>
          </Grid>
          <Grid xs={12} md={6} className="mb-margin1" textAlign={"center"}>
            {!isMobile && <span className="red_tag">What is covered ?</span>}
            <Box className="shadow-box">
              {isMobile && <span className="red_tag">What is covered ?</span>}
              <ul className="redTicklist">
                <li>Natural and Man made disaster coverage</li>
                <li>Own Damage Coverage</li>
                <li>Theft or Burglary Coverage</li>
                <li>Third Party Liabilities Coverage</li>
              </ul>
              <Box textAlign={"right"}>
                <img
                  src="./images/girl_with_pencil.svg"
                  alt=""
                  className="girlpencil_img"
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} md={6} className="mb-margin1" textAlign={"center"}>
            {!isMobile && (
              <span className="red_tag">What is not covered ?</span>
            )}
            <Box className="shadow-box">
              {isMobile && (
                <span className="red_tag">What is not covered ?</span>
              )}
              <ul className="redTicklist">
                <li>Damage due to normal wear and tear of the Bike</li>
                <li>Damage incurred due to mechanical / electrical Failure</li>
                <li>Depreciation from regular vehicle usage</li>
                <li>
                  Damage to tyres and tubes in the normal course of running
                </li>
                <li>
                  Damage/loss incurred when the policy holder was driving the
                  two wheeler with out valid license
                </li>
                <li>
                  Damage caused due to the driver being driving under the
                  influence of alcohol or drugs
                </li>
                <li>Damage due to war, mutiny or nuclear risk.</li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TWProductPage;
